import { Box, Container, Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { UsersApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import { AddLink, DeleteButton, EditLink } from 'src/components/common';
import { useAuth } from 'src/contexts';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    sortable: true,
    filterable: false
  },
  {
    field: 'first_name',
    headerName: 'Prénom',
    width: 150,
    sortable: false,
    filterable: false
  },
  {
    field: 'last_name',
    headerName: 'Nom',
    width: 200,
    sortable: false,
    filterable: false
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    sortable: false,
    filterable: false
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 150,
    sortable: false,
    filterable: false
  },
  {
    field: 'actions',
    headerName: ' ',
    width: 150,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return (
        <Box className="flex justify-center w-full">
          <EditLink to={`edit/${params.id as string}`} />
          <DeleteButton
            id={params.id as string}
            remove={UsersApi.remove}
            queryKey={['users']}
          />
        </Box>
      );
    }
  }
];

const ListUsers: React.FC = () => {
  const { can } = useAuth();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'first_name',
      headerName: 'Prénom',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'last_name',
      headerName: 'Nom',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box className="flex justify-center w-full">
            {can('users.update') && (
              <EditLink to={`edit/${params.id as string}`} />
            )}
            {can('users.delete') && (
              <DeleteButton
                id={params.id as string}
                remove={UsersApi.remove}
                queryKey={['users']}
              />
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Utilisateurs | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Liste des utilisateurs
            {can('users.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['users']}
            paginator={UsersApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListUsers;
