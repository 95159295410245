import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { InvoicesApi } from 'src/api';
import { InvoiceForm } from 'src/components/invoices';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';

const DuplicateInvoice: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: invoice, isLoading } = useQuery(
    ['invoices', id],
    () => InvoicesApi.get(id),
    {
      onError: () => {
        navigate('/app/invoices');
        toast.error('une erreur est survenue');
      }
    }
  );
  const mutation = useMutation(InvoicesApi.create, {
    onSuccess: (invoice: IInvoice) => {
      toast.success('Facture ajouté avec succés');
      queryClient
        .invalidateQueries(['invoices'])
        .then(() => {
          navigate(`/app/invoices/${invoice.id as string}`);
        })
        .catch(() => {});
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la création');
    }
  });

  const onSubmit = (data: Partial<IInvoiceForm>) => {
    mutation.mutate(data);
  };
  return (
    <>
      <Helmet>
        <title>Duplication d'une facture | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">
              Duplication d'une facture
            </Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            {isLoading ? (
              'Loading ...'
            ) : (
              <InvoiceForm
                onSubmit={onSubmit}
                defaultValues={invoice}
                type="invoice"
                mode="DUPLICATE"
              />
            )}
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default DuplicateInvoice;
