import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black';
const styles = StyleSheet.create({
  container: {
    color: 'white',
    flexDirection: 'row',
    borderBottomColor: 'black',
    backgroundColor: '#44546A',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  cell: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    width: 0
  }
});

type Props = {
  columns: any[];
};

const TableHeader: React.FC<Props> = ({ columns }) => (
  <View style={styles.container}>
    {columns.map((column) => {
      return (
        <Text key={column.key} style={{ ...styles.cell, width: column?.width }}>
          {column.name}
        </Text>
      );
    })}
  </View>
);

export default TableHeader;
