import { Button, Container, Grid } from '@material-ui/core';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { PartnersApi, ResponsiblesApi } from 'src/api';
import useDidMountEffect from 'src/hooks/use-did-mount-effect';
import { IOptionItem, QueryOperatorsEnum } from 'src/types';
import { CLIENT_ENUMS, IClient } from 'src/types/client';
import { Form, FormInput } from '../controls';
import FormPhoneInput from '../controls/phone-input';
import FormSelect from '../controls/select';
import FormSelectAutoComplete from '../controls/select-autocomplete';

type Props = {
  defaultValues?: IClient;
  onSubmit: (data: Partial<IClient>) => void;
};

const DEFAULT_VALUES = {
  title: '',
  name: '',
  registration_number: '',
  address: '',
  email: '',
  phone: '',
  partner_id: null,
  responsible_id: null
};
const ClientForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  const partnerId = useWatch({
    control: methods.control,
    name: 'partner_id'
  });

  const { data: partners, isLoading: partnersIsLoading } = useQuery(
    ['partners'],
    () => PartnersApi.paginate(1, -1)
  );

  const { data: responsibles, isLoading: responsiblesIsLoading } = useQuery(
    ['responsibles', partnerId],
    () =>
      ResponsiblesApi.paginate(1, -1, {
        criteria: [
          {
            field: 'partner_id',
            value: partnerId,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      enabled: partnerId !== null,
      select: ({ data }) =>
        data.map((responsible): IOptionItem => {
          return {
            value: responsible.id,
            label: `${responsible.first_name} ${responsible.last_name}`
          };
        })
    }
  );

  useDidMountEffect(() => {
    methods.setValue('responsible_id', null);
  }, [partnerId]);

  return (
    <Container>
      <Form
        methods={methods}
        submitHandler={(data) =>
          onSubmit({
            ...data,
            partner_id: data.partner_id!=="" ? data.partner_id : null,
            responsible_id: data.responsible_id!=="" ? data.responsible_id : null
          })
        }
      >
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={2}>
              <FormSelect
                label="Titre"
                name="title"
                options={CLIENT_ENUMS.TITLES}
              />
            </Grid>
            <Grid item xs={10}>
              <FormInput label="Nom" name="name" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Matricule fiscale" name="registration_number" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput type="email" label="Email" name="email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPhoneInput label="Téléphone" name="phone" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Adresse" name="address" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectAutoComplete
              options={
                partners?.data.map((partner): IOptionItem => {
                  return {
                    value: partner.id,
                    label: `${partner.name} ${
                      partner.hourly_rate !== null
                        ? `(Taux: ${partner.hourly_rate} DT)`
                        : ''
                    }`
                  };
                }) || []
              }
              label="Partenaire par défaut"
              name="partner_id"
              isLoading={partnersIsLoading}
            />
          </Grid>
          {partnerId && (
            <Grid item xs={12} sm={6}>
              <FormSelectAutoComplete
                options={responsibles || []}
                label="Responsable par défaut"
                name="responsible_id"
                isLoading={responsiblesIsLoading}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default ClientForm;
