import qs from 'qs';
import { Id } from 'src/types/common';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import { IRole } from 'src/types';
import privateApi from './api';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IRole>> => {
  const { data } = await privateApi.get(
    `/roles?page=${page}&limit=${limit}`,
    {
      params: { query },
      paramsSerializer: qs.stringify
    }
  );
  return data;
};

const get = async (id: Id): Promise<IRole> => {
  const { data } = await privateApi.get(`/roles/${id}`);
  return data;
};

const create = async (body: Partial<IRole>): Promise<IRole> => {
  const { data } = await privateApi.post('/roles', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<IRole>;
}): Promise<IRole> => {
  const { data } = await privateApi.patch(`/roles/${id}`, body);
  return data;
};

const remove = async (id: Id) => {
  const { data } = await privateApi.delete(`/roles/${id}`);
  return data;
};

export default {
  paginate,
  get,
  create,
  update,
  remove
};
