import { createTheme, colors } from '@material-ui/core';
import { frFR } from 'src/locales';
import typography from './typography';

const theme = createTheme(
  {
    palette: {
      background: {
        default: '#E4E4E4',
        paper: colors.common.white
      },
      primary: {
        contrastText: '#ffffff',
        main: '#0F0F0D'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#910100'
      },
      text: {
        primary: '#910100',
        secondary: '#000000'
      }
    },
    typography
  },
  frFR
);

export default theme;
