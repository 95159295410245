import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import privateApi from './api';
import qs from 'qs';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import { Id } from 'src/types/common';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IInvoice>> => {
  const { data } = await privateApi.get(
    `/invoices?page=${page}&limit=${limit}`,
    {
      params: { query },
      paramsSerializer: qs.stringify
    }
  );
  return data;
};

const get = async (id: Id): Promise<IInvoice> => {
  const { data } = await privateApi.get(`/invoices/${id}`);
  return data;
};

const create = async (body: Partial<IInvoiceForm>): Promise<IInvoice> => {
  const { data } = await privateApi.post('/invoices', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<IInvoiceForm>;
}): Promise<IInvoice> => {
  const { data } = await privateApi.patch(`/invoices/${id}`, body);
  return data;
};

const remove = async (id: Id): Promise<void> => {
  const { data } = await privateApi.delete(`/invoices/${id}`);
  return data;
};

export default {
  paginate,
  get,
  create,
  update,
  remove
};
