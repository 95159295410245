import { Box, Container, Typography } from '@material-ui/core';
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams
} from '@material-ui/data-grid';
import { PiecesApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import { AddLink, EditLink } from 'src/components/common';
import { useAuth } from 'src/contexts';

const ListPieces: React.FC = () => {
  const { can } = useAuth();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      sortable: true,
      filterable: true,
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 300,
      sortable: true,
      filterable: true,
      type: 'string',
    },
    {
      field: 'repairable',
      headerName: 'Réparable',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'boolean',
      valueFormatter: (params: GridValueFormatterParams) =>
        (params.getValue('repairable') as boolean) ? 'Oui' : 'Non'
    },
    {
      field: 'replaceable',
      headerName: 'Remplaçable',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'boolean',
      valueFormatter: (params: GridValueFormatterParams) =>
        (params.getValue('replaceable') as boolean) ? 'Oui' : 'Non'
    },
    {
      field: 'paintable',
      headerName: 'Peut être peint',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'boolean',
      valueFormatter: (params: GridValueFormatterParams) =>
        (params.getValue('paintable') as boolean) ? 'Oui' : 'Non'
    },
    {
      field: 'actions',
      headerName: ' ',
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box className="flex justify-center w-full">
            {can('pieces.update') && (
              <EditLink to={`edit/${params.id as string}`} />
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Piéce | Pitstop</title>
      </Helmet>

      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Piéce
            {can('pieces.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['pieces']}
            paginator={PiecesApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListPieces;
