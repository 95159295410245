import { useFormContext, Controller } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './index.scss';
import fr from 'react-phone-input-2/lang/fr.json';
import { get } from 'lodash-es';

type Props = TextFieldProps & {
  name: string;
  label: string;
};

const FormPhoneInput: React.FC<Props> = ({ name, label, ...rest }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const errorMessage = get(errors, `${name}.message`);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <PhoneInput
          specialLabel={label || 'Téléphone'}
          country={'tn'}
          onlyCountries={['tn']}
          localization={fr}
          disableCountryCode={true}
          masks={{ tn: '.. ... ...' }}
          /*isValid={(value) => {
            if (value.length !== 8) {
              return 'La longeur doit etre égale à 8';
            } else {
              return true;
            }
          }}*/
          inputStyle={{ width: '100%' }}
          InputLabelProps={{
            className: rest.required ? 'required-label' : '',
            required: rest.required || false
          }}
          error={errorMessage}
          helperText={errorMessage}
          {...rest}
          {...field}
        />
      )}
    />
  );
};
export default FormPhoneInput;
