import {
  Button,
  Container,
  Grid,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPartner } from 'src/types/partner';
import { Form, FormInput } from '../controls';

type Props = {
  defaultValues?: IPartner;
  onSubmit: (data: Partial<IPartner>) => void;
};

const DEFAULT_VALUES = {
  name: '',
  registration_number: '',
  hourly_rate: null
};
const PartnerForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  const { setValue, reset } = methods;

  const [hasHourlyRate, setHasHourlyRate] = useState(
    defaultValues?.hourly_rate !== null
  );

  useEffect(() => {
    if (hasHourlyRate === false) {
      setValue('hourly_rate', null);
    }
  }, [hasHourlyRate, setValue]);

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput label="Nom" name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Matricule fiscale" name="registration_number" />
          </Grid>
          <Grid item xs={12}>
            <FormInput label="Adresse" name="address" />
          </Grid>

          <Grid item xs={12} container className="py-3 flex justify-center">
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasHourlyRate}
                    onChange={(e) => setHasHourlyRate(e.target.checked)}
                  />
                }
                label="Taux horaire personalisé"
              />
            </Grid>
            {hasHourlyRate && (
              <Grid item xs={6}>
                <FormInput
                  type="number"
                  label="Taux horaire"
                  name="hourly_rate"
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default PartnerForm;
