import { Autocomplete, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormSelectAutoCompleteProps } from 'src/types/form';
import { Fragment } from 'react';
import { get } from 'lodash-es';

const FormSelectAutoComplete: React.FC<FormSelectAutoCompleteProps> = (
  props
) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const {
    name,
    label,
    options,
    groupBy,
    searchValue,
    setSearchValue,
    isLoading,
    ...rest
  } = props;

  const errorMessage = get(errors, `${name}.message`);

  return (
    <Fragment>
      <Controller
        render={({ field }) => (
          <Autocomplete
            className="w-full"
            options={[{ value: '', label: '' }, ...options]}
            groupBy={groupBy}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => {
              if (value.value === option.value) {
                return true;
              }
              return false;
            }}
            loading={isLoading}
            onChange={(e, option) => field.onChange(option?.value || '')}
            value={
              options.find((option) => option.value === field.value) || {
                value: '',
                label: searchValue || ''
              }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={errorMessage}
                helperText={errorMessage}
                variant="outlined"
                onChange={(e) =>
                  setSearchValue && setSearchValue(e.target.value)
                }
              />
            )}
          />
        )}
        name={name}
        control={control}
        {...rest}
      />
    </Fragment>
  );
};

export default FormSelectAutoComplete;
