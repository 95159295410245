import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { ResponsiblesApi } from 'src/api';
import DataTable from 'src/components/datatable';
import { EditLink } from 'src/components/common';
import { Id } from 'src/types/common';
import { QueryOperatorsEnum } from 'src/types/paginate';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    sortable: true,
    filterable: true,
    type: 'number'
  },
  {
    field: 'first_name',
    headerName: 'Prénom',
    width: 150,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'last_name',
    headerName: 'Nom',
    width: 150,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 150,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'actions',
    headerName: ' ',
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return <EditLink to={`responsibles/edit/${params.id as string}`} />;
    }
  }
];

type Props = {
  id: Id;
};

const ResponsiblesList: React.FC<Props> = ({ id }) => {
  return (
    <DataTable
      queryKey={['partners', 'responsibles', id as string]}
      paginator={ResponsiblesApi.paginate}
      columns={columns}
      query={{
        criteria: [
          {
            field: 'partner_id',
            value: id,
            operator: QueryOperatorsEnum.equals
          }
        ]
      }}
    />
  );
};

export default ResponsiblesList;
