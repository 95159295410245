import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import { ToWords } from 'src/utils';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: 'black'
  },
  cost: {
    textAlign: 'center'
  }
});

const columns = (type: 'invoice' | 'quote') => [
  {
    key: 'header',
    name: `Arrétés le présent ${
      type === 'invoice' ? 'Facture' : 'Devis'
    } à la somme de :`,
    width: '100%'
  }
];

type Props = {
  invoice: IInvoiceForm | IInvoice;
  type: 'invoice' | 'quote';
};
const toWords = new ToWords({
  localeCode: 'tn-TN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false
  }
});

const InvoiceCostInWords: React.FC<Props> = ({ invoice, type }) => {
  const total = (
    (invoice.operations_sub_total + invoice.pieces_sub_total) *
      (1 + invoice.tva / 100) +
    invoice.stamp_price
  ).toFixed(3);
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns(type)} />
      <>
        <TableRow styles={{ height: 40 }}>
          <TableCell styles={styles.cost} width={'100%'}>
            #{toWords.convert(total)}#
          </TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceCostInWords;
