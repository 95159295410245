import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Paper, List } from '@material-ui/core';
import {
  //BarChart as BarChartIcon,
  People as PeopleIcon,
  Accessibility as AccessibilityIcon,
  //Person as PersonIcon,
  GroupWork as GroupWorkIcon,
  Receipt as ReceiptIcon,
  DriveEtaOutlined as DriveEtaIcon,
  Build as BuildIcon,
  Settings as SettingsIcon,
  FitnessCenter as FitnessCenterIcon
} from '@material-ui/icons';
import NavItem from './NavItem';
import { useAuth } from 'src/contexts';

type Props = {
  onMobileClose: () => void;
  openMobile: boolean;
};

const DashboardSidebar: React.FC<Props> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { user, can } = useAuth();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const items = useMemo(
    () => [
      /*{
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },*/
      {
        href: '/app/users',
        icon: PeopleIcon,
        title: 'Utilisateurs',
        show: can('users.list')
      },
      {
        href: '/app/roles',
        icon: AccessibilityIcon,
        title: 'Rôles',
        show: can('roles.list')
      },
      {
        href: '/app/invoices',
        icon: ReceiptIcon,
        title: 'Factures',
        show: can('invoices.list')
      },
      {
        href: '/app/quotes',
        icon: ReceiptIcon,
        title: 'Devis',
        show: can('quotes.list')
      },
      {
        href: '/app/clients',
        icon: PeopleIcon,
        title: 'Clients',
        show: can('clients.list')
      },
      {
        href: '/app/partners',
        icon: GroupWorkIcon,
        title: 'Partenaires',
        show: can('partners.list')
      },
      {
        href: '/app/cars',
        icon: DriveEtaIcon,
        title: 'Voitures',
        show: can('cars.list')
      },
      {
        href: '/app/pieces',
        icon: BuildIcon,
        title: 'Piéces',
        show: can('pieces.list')
      },
      {
        href: '/app/operations',
        icon: FitnessCenterIcon,
        title: 'Opérations',
        show: can('operations.list')
      },
      {
        href: '/app/settings',
        icon: SettingsIcon,
        title: 'Pramétres',
        show: can('settings.list')
      }
    ],
    [user,can]
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ pb: 2 }}>
        <List>
          {items.map(
            (item) =>
              item.show && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              )
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Paper sx={{ display: { lg: 'none', xs: 'block' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Paper>
      <Paper sx={{ display: { lg: 'block', xs: 'none' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Paper>
    </>
  );
};

export default DashboardSidebar;
