import React, { Fragment } from 'react';
import TableRow from './table-row';
import TableCell from './table-cell';

type Props = {
  columns: any[];
  rowsCount: number;
};
const InvoiceTableBlankSpace: React.FC<Props> = ({ columns, rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <TableRow key={`BR${i}`}>
      {columns.map((column) => (
        <TableCell
          key={column.key}
          styles={column.styles}
          width={column.width}
        />
      ))}
    </TableRow>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableBlankSpace;
