import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClientsApi } from 'src/api';
import ClientForm from 'src/components/clients/form';
import { IClient } from 'src/types/client';

const CreateClient: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(ClientsApi.create, {
    onSuccess: () => {
      toast.success('Client ajouté avec succés');
      queryClient.invalidateQueries(['clients']).catch(() => {});
      navigate('/app/clients');
    },
    onError: () => {
      toast.error("une erreur est survenue lors de l'ajout");
    }
  });

  const onSubmit = (data: Partial<IClient>) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Création d'un client | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'un client</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <ClientForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateClient;
