import { AxiosError } from 'axios';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';
import { UsersApi } from 'src/api';
import { IUser } from 'src/types/user';

const useFetchUser = ({
  options
}: {
  options?: UseQueryOptions<IUser | null, AxiosError>;
}): UseQueryResult<IUser | null, AxiosError> => {
  return useQuery<IUser | null, AxiosError>(
    'authenticated-user',
    () => UsersApi.user(),
    { ...options }
  );
};

export default useFetchUser;
