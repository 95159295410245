import { useFormContext, Controller } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, InputLabel } from '@material-ui/core';
import { FormSelectProps } from 'src/types/form';
import { Fragment } from 'react';
import { get } from 'lodash-es';

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { label, name, options, required, ...rest } = props;
  const errorMessage = get(errors, `${name}.message`);

  return (
    <Fragment>
      <Controller
        render={({ field }) => (
          <FormControl fullWidth={true} error={errorMessage}>
            <InputLabel htmlFor={name}>
              {label} {required ? <span className="req-label">*</span> : null}
            </InputLabel>
            <Select
              id={name}
              {...field}
              {...props}
              {...(rest.multiple && {
                renderValue: (selected) => {
                  const selectedLabels: string[] = options.reduce(
                    (result: string[], option) => {
                      if (
                        (selected as string[]).includes(option.value as string)
                      ) {
                        result.push(option.label);
                      }
                      return result;
                    },
                    []
                  );
                  return selectedLabels.join(', ');
                }
              })}
            >
              {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {rest.multiple && (
                    <Checkbox
                      readOnly
                      checked={field.value.includes(item.value)}
                    />
                  )}
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>
        )}
        control={control}
        name={name}
        {...rest}
      />
    </Fragment>
  );
};

export default FormSelect;
