import qs from 'qs';
import { Id } from 'src/types/common';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import { IPartner } from 'src/types/partner';
import privateApi from './api';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IPartner>> => {
  const { data } = await privateApi.get(
    `/partners?page=${page}&limit=${limit}`,
    {
      params: { query },
      paramsSerializer: qs.stringify
    }
  );
  return data;
};

const get = async (id: Id): Promise<IPartner> => {
  const { data } = await privateApi.get(`/partners/${id}`);
  return data;
};

const create = async (body: Partial<IPartner>): Promise<IPartner> => {
  const { data } = await privateApi.post('/partners', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<IPartner>;
}): Promise<IPartner> => {
  const { data } = await privateApi.patch(`/partners/${id}`, body);
  return data;
};

const remove = async (id: Id) => {
  const { data } = await privateApi.delete(`/partners/${id}`);
  return data;
};

export default {
  paginate,
  get,
  create,
  update,
  remove
};
