import { Box, Container, Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { OperationsApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import { AddLink, EditLink } from 'src/components/common';
import { useAuth } from 'src/contexts';

const ListOperations: React.FC = () => {
  const { can } = useAuth();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'volume',
      headerName: 'Volume',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'price',
      headerName: 'Prix',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'actions',
      headerName: ' ',
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box className="flex justify-center w-full">
            {can('operations.edit') && (
              <EditLink to={`edit/${params.id as string}`} />
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Opérations | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Liste des opérations
            {can('operations.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['operations']}
            paginator={OperationsApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListOperations;
