import { ThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import { AuthProvider } from '.';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

const AppProviders: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ConfirmProvider>
            <GlobalStyles />
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={true}
              closeOnClick={true}
              pauseOnHover={true}
              draggable={true}
            />
            {children}
          </ConfirmProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
