import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';

const cellStyles = StyleSheet.create({
  cell: {
    borderRightColor: 'black',
    borderRightWidth: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    paddingHorizontal: 4,
    paddingTop: 5
  }
});

type Props = {
  width: number | string;
  styles?: any;
};

const TableCell: React.FC<Props> = ({ width, styles, children }) => {
  return (
    <View style={{ ...cellStyles.cell, ...styles, width: width }}>
      <Text>{children}</Text>
    </View>
  );
};

export default TableCell;
