import { Box } from '@material-ui/core';
import React, { memo } from 'react';

interface Props {
  children?: React.ReactNode;
  index: string | number;
  value: string | number;
}

const TabPanel: React.FC<Props> = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index as string}`}
      aria-labelledby={`simple-tab-${index as string}`}
      sx={{
        height: '100%'
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: '100%'
          }}
          p={3}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default memo(TabPanel);