import { Grid, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AuthenticationApi } from 'src/api';

const TwoFactorBackupCodes: React.FC = () => {
  const { data: codes } = useQuery<string[], AxiosError>(
    '2fa-recovery-codes',
    () => AuthenticationApi.getTwoFactorRecoveryCodes()
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item>
        <Typography color="primary" align="center">
          Avec l'authentification à deux facteurs activé, vous aurez besoin de
          ces codes de secours si jamais vous perdez votre appareil
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {codes &&
          codes.map((code, index) => {
            return (
              <Typography key={code} color="accent" align="center">
                {`Code ${index + 1}: ${code}`}
              </Typography>
            );
          })}
      </Grid>
      <Grid item>
        <Typography color="primary" align="center">
          Sauvegarder ces codes dans un endroit sur pour pouvoir vous connectez
          si vous perdez votre appareil
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TwoFactorBackupCodes;
