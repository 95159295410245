import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import { Id } from 'src/types/common';

type Props = {
  id: Id;
  remove: (id: Id) => Promise<any>;
  queryKey: string[] | string;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 3,
      color: theme.palette.primary.contrastText,
      width: '30px',
      height: '30px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1rem 0.5rem'
    }
  };
});

const DeleteButton: React.FC<Props> = ({ id, remove, queryKey }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const mutation = useMutation(remove, {
    onSuccess: () => {
      toast.success('élement supprimer avec succés');
      queryClient.invalidateQueries(queryKey).catch(() => {});
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la suppression');
    }
  });

  const onDelete = () => {
    confirm({
      title: 'Êtes-vous sûr?',
      description: `Cela supprimera définitivement l'élément avec l'identifiant ${id}`
    })
      .then(() => {
        mutation.mutate(id);
      })
      .catch(() => {});
  };
  return (
    <Tooltip title="Supprimer">
    <button className={classes.root} onClick={onDelete}>
      <DeleteIcon className="w-full h-full" />
    </button>
    </Tooltip>
  );
};

export default DeleteButton;
