import { Box, Container, Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { PartnersApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import { AddLink, DetailsLink, EditLink } from 'src/components/common';
import { useAuth } from 'src/contexts';

const ListPartners: React.FC = () => {
  const { can } = useAuth();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 300,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'registration_number',
      headerName: 'Matricule fiscale',
      width: 200,
      sortable: true,
      filterable: true,
      type: 'string'
    },
    {
      field: 'hourly_rate',
      headerName: 'Taux horaire',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box className="flex justify-center w-full">
            {can('partners.show') && (
              <DetailsLink to={`${params.id as string}`} />
            )}
            {can('partners.update') && (
              <EditLink to={`edit/${params.id as string}`} />
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Partenaires | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Liste des partenaires
            {can('partners.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['partners']}
            paginator={PartnersApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListPartners;
