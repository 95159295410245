import { Link } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';

type Props = {
  to?: string;
  target?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 3,
      color: theme.palette.primary.contrastText,
      width: '30px',
      height: '30px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1rem'
    }
  };
});

const AddLink: React.FC<Props> = ({ to = 'create', target = '_self' }) => {
  const classes = useStyles();
  return (
    <Tooltip title="Ajouter">
      <Link className={classes.root} to={to} target={target}>
        <AddIcon className="w-full h-full" />
      </Link>
    </Tooltip>
  );
};

export default AddLink;
