import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { RolesApi } from 'src/api';
import { IOptionItem } from 'src/types';
import { IUser, IUserPayload } from 'src/types/user';
import { Form, FormInput } from '../controls';
import FormPhoneInput from '../controls/phone-input';
import FormSelect from '../controls/select';
import { createUserSchema, updateUserSchema } from './schemas';

type Props = {
  defaultValues?: IUser;
  onSubmit: (data: Partial<IUserPayload>) => void;
  mode?: 'CREATE' | 'UPDATE';
};

const DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  password: '',
  password_confirmation: '',
  roles_names: []
};
const UserForm: React.FC<Props> = ({
  defaultValues,
  onSubmit,
  mode = 'CREATE'
}) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES,
    resolver: yupResolver(
      mode === 'CREATE' ? createUserSchema : updateUserSchema
    ),
    reValidateMode: 'onChange'
  });

  const { data: roles } = useQuery(['roles'], () => RolesApi.paginate(1, -1), {
    select: (res) =>
      res.data.map((role) => ({ label: role.label, value: role.name } as IOptionItem))
  });

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormInput name="first_name" label="Prénom" />
          </Grid>
          <Grid item xs={6}>
            <FormInput name="last_name" label="Nom" />
          </Grid>
          <Grid item xs={6}>
            <FormInput name="email" label="Email" />
          </Grid>
          <Grid item xs={6}>
            <FormPhoneInput label="Téléphone" name="phone" />
          </Grid>
          <Grid item xs={6}>
            <FormInput name="password" label="Mot de passe" type="password" />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="password_confirmation"
              label="Confirmation du mot de passe"
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect name="roles_names" label="Rôles" options={roles || []}  multiple />
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default UserForm;
