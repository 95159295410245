import axios, { AxiosInstance } from 'axios';

export const publicApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL as string
});

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string
});

publicApi.defaults.withCredentials = true;
api.defaults.withCredentials = true;

export default api;
