import * as yup from 'yup';
export const updatePasswordSchema = yup.object().shape({
  old_password: yup.string().required('Le mot de passe est obligatoire.'),
  password: yup
    .string()
    .required('Le nouveau mot de passe est obligatoire.'),
  password_confirmation: yup
    .string()
    .required('La confirmation du mot de passe est obligatoire.')
    .oneOf(
      [yup.ref('password'), ''],
      'La confirmation du mot de passe doit etre égale au nouveau mot de passe.'
    )
});
export default {
  updatePasswordSchema
};
