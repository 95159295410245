/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Container, Grid } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CarsApi } from 'src/api';
import FormSelectAutoComplete from 'src/components/controls/select-autocomplete';
import useDebounce from 'src/hooks/use-debounce';
import { IClientCarForm } from 'src/types/client-car';
import { IOptionItem } from 'src/types/form';
import { Form, FormInput } from '../../controls';
import { Add as AddIcon } from '@material-ui/icons';
import { sortBy } from 'lodash-es';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientCarSchema } from './schemas';

type Props = {
  defaultValues?: IClientCarForm;
  onSubmit: (data: Partial<IClientCarForm>) => void;
};

const DEFAULT_VALUES = {
  car: null,
  owner: null,
  plate_number: '',
  painting_ref: ''
};
const ClientCarForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const notInitialRender = useRef(false);
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES,
    resolver: yupResolver(clientCarSchema),
    reValidateMode: 'onChange'
  });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const {
    data: cars,
    isLoading: carsIsLoading,
    refetch
  } = useQuery(['cars'], () => CarsApi.search(debouncedSearch), {
    enabled: false,
    initialData: defaultValues?.car ? [defaultValues.car] : [],
    select: (data) =>
      sortBy(
        data.map((car): IOptionItem => {
          return {
            value: car.id,
            label: car.make_name + ' ' + car.model
          };
        }),
        'label'
      )
  });

  useEffect(() => {
    if (defaultValues?.car_id) {
      methods.setValue('car_id', defaultValues.car_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notInitialRender.current && debouncedSearch !== '') {
      refetch().catch(() => {});
    } else {
      notInitialRender.current = true;
    }
  }, [debouncedSearch, refetch]);

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container>
          <Grid container spacing={2} className="py-3 flex justify-center">
            <Grid item xs={12}>
              <FormInput label="Plaque d'immatriculation" name="plate_number" />
            </Grid>
            <Grid item xs={12}>
              <FormInput label="Référence de peinture" name="painting_ref" />
            </Grid>
          </Grid>
          <Grid item xs={12} className=" flex justify-center">
            <FormSelectAutoComplete
              options={cars || []}
              label="Voiture"
              name="car_id"
              isLoading={carsIsLoading}
              setSearchValue={setSearch}
              searchValue={search}
              groupBy={(option) => option.label.split(' ')[0]}
            />
            <Box className="pl-5 h-full">
              <Link className="h-full" to={'/app/cars/create'} target="_blank">
                <Button
                  className="h-full"
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon />
                </Button>
              </Link>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default ClientCarForm;
