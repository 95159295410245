import {
  DurationUnitEnum,
  InvoiceType,
  PaymentModeEnum,
  PaymentStatusEnum
} from 'src/types/invoice';
import * as yup from 'yup';

export const stepperValidationSchema = (
  type: InvoiceType
): Array<yup.ObjectSchema> => {
  return [
    yup.object().shape({
      client_id: yup
        .string()
        .nullable()
        .required('Vous devez choisir un client'),
      client_car_id: yup
        .string()
        .nullable()
        .required('Vous devez choisir une voiture pour le client'),
      partner_id: yup
        .string()
        .nullable()
        .required('Vous devez choisir un partenaire'),
      responsible_id: yup
        .string()
        .nullable()
        .required('Vous devez choisir un responsable du partenaire')
    }),
    yup.object().shape({
      operations: yup.array().of(
        yup.object().shape({
          operation_id: yup
            .string()
            .nullable()
            .required('Vous devez choisir une operation'),
          piece_id: yup
            .string()
            .nullable()
            .required('Vous devez choisir une pièce'),
          duration: yup
            .number()
            .typeError('Vous devez indiquer une durée')
            .positive('La durée doit être un nombre positive supérieur à zero')
            .required('Vous devez indiquer une durée'),
          hourly_rate: yup
            .number()
            .typeError('Vous devez indiquer un taux')
            .positive('La taux doit être un nombre positive supérieur à zero')
            .required('Vous devez indiquer un taux'),
          total: yup
            .number()
            .positive('Le total doit être un nombre positive supérieur à zero')
            .required()
        })
      ),
      operations_sub_total: yup.number().min(0).required()
    }),
    yup
      .object()
      .shape({
        operations: yup.array(),
        operations_sub_total: yup.number().min(0).required(),
        pieces: yup.array().of(
          yup.object().shape({
            piece_id: yup
              .string()
              .nullable()
              .required('Vous devez choisir une pièce'),
            reference: yup.string(),
            quantity: yup
              .number()
              .typeError('Vous devez indiquer une quantité')
              .positive(
                'La quantité doit être un nombre positive supérieur à zéro'
              )
              .integer()
              .required('Vous devez indiquer la quantité'),
            unit_price: yup
              .number()
              .typeError('Vous devez indiquer un prix unitaire')
              .positive('Le prix doit être un nombre positive supérieur à zero')
              .required('Vous devez indiquer le prix unitaire'),
            total: yup
              .number()
              .positive(
                'Le total doit être un nombre positive supérieur à zero'
              )
              .required()
          })
        ),
        pieces_sub_total: yup.number().min(0).required()
      })
      .test(
        'selected-operations-or-pieces',
        'Vous devez au moins ajouter une operation ou une pièce',
        function (value) {
          return (
            ((value?.operations?.length || 0) > 0 &&
              (value?.operations_sub_total || 0) > 0) ||
            ((value?.pieces?.length || 0) > 0 &&
              (value?.pieces_sub_total || 0) > 0)
          );
        }
      ),
    yup.object().shape({
      payment_mode: yup
        .mixed()
        .oneOf(Object.values(PaymentModeEnum))
        .required(),
      comments: yup.string().nullable(),
      stamp_price: yup
        .number()
        .typeError('Vous devez indiquer un prix pour le timbre')
        .required('Vous devez indiquer le prix du timbre'),
      tva: yup
        .number()
        .typeError('Vous devez indiquer une TVA')
        .required('Vous devez indiquer la TVA'),
      hourly_rate: yup
        .number()
        .typeError('Vous devez indiquer un taux')
        .required('Vous devez indiquer le taux'),
      working_duration: yup
        .number()
        .typeError('Vous devez indiquer une durée des travaux')
        .min(
          1,
          'La durée des travaux doit être un nombre supérieur ou égal à 1'
        )
        .required('Vous devez indiquer la durée des travaux'),
      working_duration_unit: yup
        .mixed()
        .oneOf(Object.values(DurationUnitEnum))
        .required(),
      date: yup.date().required(),
      ...(type === 'invoice' && {
        payment_status: yup
          .mixed()
          .oneOf(
            Object.values(PaymentStatusEnum).filter(
              (status) => status !== PaymentStatusEnum.NOT_APPLICABLE
            ),
            'Vous devez choisir le status du paiement'
          )
          .required('Vous devez choisir le status du paiement')
      })
    })
  ];
};
