import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { InvoicesApi } from 'src/api';
import DataTable from 'src/components/datatable';
import { DeleteButton, DetailsLink, EditLink } from 'src/components/common';
import { Id } from 'src/types/common';
import { QueryOperatorsEnum } from 'src/types/paginate';
import { IClient } from 'src/types/client';
import { ICar } from 'src/types/car';
import { IClientCar } from 'src/types/client-car';
import { Box } from '@material-ui/core';

type Props = {
  id: Id;
};

const columns: GridColDef[] = [
  {
    field: 'internal_id',
    headerName: 'ID',
    width: 100,
    sortable: true,
    filterable: true,
    type: 'number'
  },
  {
    field: 'client.name',
    headerName: 'Client',
    width: 200,
    sortable: false,
    filterable: true,
    type: 'string',
    valueGetter: (params) => {
      const client = params.row.client as IClient;
      return `${client.title} ${client.name}`;
    }
  },
  {
    field: 'client_car.car.make_name',
    headerName: 'Marque',
    width: 200,
    type: 'string',
    sortable: false,
    filterable: true,
    valueGetter: (params) => {
      const car = params.row.client_car.car as ICar;
      return car.make_name;
    }
  },
  {
    field: 'client_car.car.model',
    headerName: 'Modéle',
    width: 200,
    type: 'string',
    sortable: false,
    filterable: true,
    valueGetter: (params) => {
      const car = params.row.client_car.car as ICar;
      return car.model;
    }
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    sortable: true,
    filterable: true,
    type: 'date'
  },
  {
    field: 'actions',
    headerName: ' ',
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      const id = params.id as string;
      return (
        <Box className="flex justify-center w-full">
          <DetailsLink to={`/app/invoices/${params.id as string}`} />
          <EditLink to={`/app/invoices/edit/${id}`} />
          <DeleteButton
            id={id}
            remove={InvoicesApi.remove}
            queryKey={'invoices'}
          />
        </Box>
      );
    }
  }
];

const ListClientInvoices: React.FC<Props> = ({ id }) => {
  return (
    <DataTable
      queryKey={['client-invoices', id as string]}
      paginator={InvoicesApi.paginate}
      columns={columns}
      query={{
        criteria: [
          {
            field: 'client_id',
            value: id,
            operator: QueryOperatorsEnum.equals
          }
        ]
      }}
    />
  );
};

export default ListClientInvoices;
