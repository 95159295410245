import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: 'black'
  }
});

const columns = [
  {
    key: 'header',
    name: 'Remarques',
    width: '100%'
  }
];

type Props = {
  invoice: IInvoiceForm | IInvoice;
};

const InvoiceCommentArea: React.FC<Props> = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <>
        <TableRow styles={{ height: 80 }}>
          <TableCell width={'100%'}>
            {invoice.comments !== '' ? invoice.comments : 'Aucune'}
          </TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceCommentArea;
