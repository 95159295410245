import { View, StyleSheet } from '@react-pdf/renderer';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: 'black'
  }
});

const columns = [
  {
    key: 'header',
    name: 'Caché et Signature',
    width: '100%'
  }
];
const InvoiceSignature: React.FC = () => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <>
        <TableRow styles={{ height: 186 }}>
          <TableCell width={'100%'}></TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceSignature;
