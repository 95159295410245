import { Grid } from '@material-ui/core';
import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFetchSettingByName } from 'src/queries';
import { InvoiceType, INVOICE_ENUMS } from 'src/types/invoice';
import { FormInput } from '../controls';
import FormSelect from '../controls/select';
import { useAuth } from 'src/contexts';

type Props = {
  type: InvoiceType;
};

const InvoiceAdditionalInformations: React.FC<Props> = ({ type }) => {
  const methods = useFormContext();
  const { user } = useAuth();

  useFetchSettingByName({
    name: 'tva',
    options: {
      onSuccess: (data) => {
        if (data) {
          methods.setValue('tva', parseFloat(data.value));
        }
      }
    }
  });

  useFetchSettingByName({
    name: 'stamp_price',
    options: {
      onSuccess: (data) => {
        if (data) {
          methods.setValue('stamp_price', parseFloat(data.value));
        }
      }
    }
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormSelect
          options={INVOICE_ENUMS.PAYMENT_MODES}
          label="Mode de paiement"
          name="payment_mode"
          defaultValue={methods.getValues('payment_mode')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name="date"
          label={`Date ${type === 'invoice' ? 'de la facture' : 'du devis'} `}
          type="date"
          disabled={!user?.roles_names.includes('super_admin')}
          defaultValue={methods.getValues('date')}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          name="comments"
          label="Remarque"
          type="text"
          rows={4}
          multiline
          defaultValue={methods.getValues('comments')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name="working_duration"
          label="Durée travaux"
          type="number"
          inputProps={{
            min: 1
          }}
          defaultValue={methods.getValues('working_duration')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormSelect
          options={INVOICE_ENUMS.DURATION_UNITS}
          label="Unité durée travaux"
          name="working_duration_unit"
          defaultValue={methods.getValues('working_duration_unit')}
        />
      </Grid>
      <Grid item xs={type === 'invoice' ? 4 : 6}>
        <FormInput
          name="tva"
          label="TVA"
          type="number"
          disabled
          defaultValue={methods.getValues('tva')}
        />
      </Grid>
      <Grid item xs={type === 'invoice' ? 4 : 6}>
        <FormInput
          name="stamp_price"
          label="Prix timbre"
          type="number"
          disabled
          defaultValue={methods.getValues('stamp_price')}
        />
      </Grid>
      {type === 'invoice' && (
        <Grid item xs={4}>
          <FormSelect
            options={INVOICE_ENUMS.PAYMENT_STATUSES}
            label="Status du paiement"
            name="payment_status"
            defaultValue={methods.getValues('payment_status')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(InvoiceAdditionalInformations);
