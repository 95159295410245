import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ClientsApi } from 'src/api';
import ClientForm from 'src/components/clients/form';
import { IClient } from 'src/types/client';

const EditClient: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: client, isLoading } = useQuery(
    ['clients', id],
    () => ClientsApi.get(id),
    {
      onError: () => {
        navigate('/app/clients');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation(ClientsApi.update, {
    onSuccess: () => {
      toast.success('Client modifié avec succés');
      queryClient.invalidateQueries(['clients']).catch(() => {});
      navigate('/app/clients');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IClient>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'un client | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'un client</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <ClientForm onSubmit={onSubmit} defaultValues={client} />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditClient;
