import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';

const Dashboard: React.FC = () => (
  <>
    <Helmet>
      <title>Dashboard | Pitstop</title>
    </Helmet>
    <Box display="flex" justifyContent="center">
      <img alt="Logo" src="/static/logo.png" />
    </Box>
  </>
);
export default Dashboard;
