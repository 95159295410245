import { Box, Card, Container, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { PiecesApi } from 'src/api';
import PieceForm from 'src/components/pieces/form';
import { IPiece } from 'src/types/piece';

const EditPiece: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: piece, isLoading } = useQuery(
    ['pieces', id],
    () => PiecesApi.get(id),
    {
      onError: () => {
        navigate('/app/pieces');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation<
    IPiece,
    AxiosError,
    { id: string; body: Partial<IPiece> }
  >(PiecesApi.update, {
    onSuccess: () => {
      toast.success('Pièce modifié avec succés');
      queryClient.invalidateQueries(['pieces']).catch(() => {});
      navigate('/app/pieces');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IPiece>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'une piéce | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'une pièce</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <PieceForm
                  onSubmit={onSubmit}
                  defaultValues={piece}
                  errors={mutation?.error?.response?.data}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditPiece;
