import * as yup from 'yup';
export const createUserSchema = yup.object().shape({
  first_name: yup.string().required('Le prénom est obligatoire.'),
  last_name: yup.string().required('Le nom est obligatoire.'),
  email: yup
    .string()
    .email("Veuillez vérifier le format de l'email")
    .required("L'email est obligatoire."),
  phone: yup.string().required('Le téléphone est obligatoire.'),
  password: yup.string().required('Le mot de passe est obligatoire.'),
  password_confirmation: yup
    .string()
    .required('La confirmation du mot de passe est obligatoire.')
    .oneOf(
      [yup.ref('password'), ''],
      'La confirmation du mot de passe doit etre égale au mot de passe.'
    )
});

export const updateUserSchema = yup.object().shape({
  first_name: yup.string().required('Le prénom est obligatoire.'),
  last_name: yup.string().required('Le nom est obligatoire.'),
  email: yup
    .string()
    .email("Veuillez vérifier le format de l'email")
    .required("L'email est obligatoire."),
  phone: yup.string().required('Le téléphone est obligatoire.'),
  password: yup.string(),
  password_confirmation: yup
    .string()
    .when('password', {
      is: (password) => password && password !== '',
      then: yup
        .string()
        .required('La confirmation du mot de passe est obligatoire.')
    })

    .oneOf(
      [yup.ref('password'), ''],
      'La confirmation du mot de passe doit etre égale au mot de passe.'
    )
});
export default {
  createUserSchema,
  updateUserSchema
};
