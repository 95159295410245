import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { groupBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { PermissionsApi } from 'src/api';
import { IRole } from 'src/types';
import { Form, FormInput } from '../controls';

type Props = {
  defaultValues?: IRole;
  onSubmit: (data: IRole) => void;
};

const DEFAULT_VALUES = {
  name: '',
  label: '',
  permissions_names: []
};
const RoleForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ['permissions'],
    () => PermissionsApi.paginate(1, -1),
    {
      select: (res) => {
        return groupBy(res.data, 'resource_name');
      }
    }
  );
  console.log(permissions);
  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput label="Nom" name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Label" name="label" />
          </Grid>
          {isLoadingPermissions ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12}>
              <Typography variant="h3" color="primary" textAlign="center">
                Selectionnez les permissions
              </Typography>

              <Controller
                name="permissions_names"
                control={methods.control}
                render={({ field }) => (
                  <Grid container>
                    {Object.entries(permissions || {}).map(
                      ([key, permissionsGroup]) => (
                        <Grid item key={key} xs={12} sm={6} md={4} my={2}>
                          <Typography variant="h4">{key}</Typography>
                          <Grid container>
                            {permissionsGroup.map((permission) => (
                              <Grid key={permission.id} item xs={12}>
                                <FormControlLabel
                                  label={permission.label}
                                  control={
                                    <Checkbox
                                      value={permission.name}
                                      checked={field.value.includes(
                                        permission.name
                                      )}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          field.onChange([
                                            ...field.value,
                                            event.target.value
                                          ]);
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              (value: string) =>
                                                value !== event.target.value
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default RoleForm;
