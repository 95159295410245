import qs from 'qs';
import { Id } from 'react-toastify';
import { ICar } from 'src/types/car';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import privateApi from './api';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<ICar>> => {
  const { data } = await privateApi.get(`/cars?page=${page}&limit=${limit}`, {
    params: { query },
    paramsSerializer: qs.stringify
  });
  return data;
};

const search = async (search = ''): Promise<ICar[]> => {
  const { data } = await privateApi.get(`/cars/search?search=${search}`);
  return data;
};

const get = async (id: Id): Promise<ICar> => {
  const { data } = await privateApi.get(`/cars/${id}`);
  return data;
};

const create = async (body: Partial<ICar>): Promise<ICar> => {
  const { data } = await privateApi.post('/cars', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<ICar>;
}): Promise<ICar> => {
  const { data } = await privateApi.patch(`/cars/${id}`, body);
  return data;
};

const remove = async (id: Id): Promise<void> => {
  const { data } = await privateApi.delete(`/cars/${id}`);
  return data;
};

export default {
  paginate,
  search,
  get,
  create,
  update,
  remove
};
