import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import InvoiceCarInfos from './invoice-car-infos';
import InvoiceCommentArea from './invoice-comment-area';
import InvoiceCostHT from './invoice-cost-ht';
import InvoiceCostInWords from './invoice-cost-in-words';
import InvoiceCostTTC from './invoice-cost.ttc';
import InvoiceSignature from './invoice-signature';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
  },
  costs: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10
  },
  signature: {
    marginLeft: 10
  },
  invoiceInfoValues: {
    padding: 2,
    color: 'black'
  }
});

type Props = {
  invoice: IInvoiceForm | IInvoice;
  type: 'invoice' | 'quote';
};

const InvoiceSummary: React.FC<Props> = ({ invoice, type }) => {
  return (
    <>
      <InvoiceCarInfos invoice={invoice} />
      <InvoiceCommentArea invoice={invoice} />
      <View style={styles.container}>
        <View style={styles.costs}>
          <InvoiceCostHT invoice={invoice} />
          <InvoiceCostTTC invoice={invoice} />
        </View>
        <View style={styles.signature}>
          <InvoiceSignature />
        </View>
      </View>
      <InvoiceCostInWords invoice={invoice} type={type} />
    </>
  );
};

export default InvoiceSummary;
