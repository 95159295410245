import { Button, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import TwoFactorQrCodeModal from '../modals/enable-two-factor';
import { useAuth } from 'src/contexts';
const AccountTwoFactor = () => {
  const { user, enableTwoFactor, disableTwoFactor, setUser } = useAuth();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);

  const onEnableTwoFactor = () => {
    enableTwoFactor()
      .then((user) => {
        setUser(user);
        setOpen(true);
      })
      .catch(() => {});
  };

  const onDisableTwoFactor = () => {
    confirm({
      title: "Désactiver l'authentification à deux facteurs ?",
      description:
        "Si vous désactiver l'authentification à deux facteurs vous dévriez reconfigurer votre application d'authentification si vous décidez de la réutiliser"
    })
      .then(() => {
        disableTwoFactor().catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <>
      <Grid container spacing={6} wrap="wrap">
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
          xs={12}
        >
          {user?.two_factor_secret !== null ? (
            <Button
              color="primary"
              variant="contained"
              onClick={onDisableTwoFactor}
            >
              Désactiver l'authentification à deux facteurs
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={onEnableTwoFactor}
            >
              Activer l'authentification à deux facteurs
            </Button>
          )}
        </Grid>
      </Grid>

      {open && <TwoFactorQrCodeModal open={open} setOpen={setOpen} />}
    </>
  );
};
export default AccountTwoFactor;
