import { forwardRef, HTMLProps } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

/*interface Props extends HTMLProps<HTMLFormElement> {
  methods: UseFormReturn;
  // @todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitHandler: (data: any) => void;
}*/

type Props = HTMLProps<HTMLFormElement> & {
  methods: UseFormReturn<any>;
  // @todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitHandler: (data: any) => void;
};

const Form = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { submitHandler, methods, ...rest } = props;

  return (
    <FormProvider {...methods}>
      <form
        {...rest}
        ref={ref}
        onSubmit={methods.handleSubmit(submitHandler)}
        noValidate
      />
    </FormProvider>
  );
});

export default Form;
