/* eslint-disable react/jsx-wrap-multilines */
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import AccountPassword from './AccountPassword';
import AccountTwoFactor from './AccountTwoFactor';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    width: '100%'
  }
}));
const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    width: '100%',
    flexDirection: 'column',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);
const AccountSecurity: React.FC = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string>('');
  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };
  return (
    <Card>
      <CardHeader
        subheader="Gérer la sécurité de votre compte"
        title="Sécurité"
      />
      <Divider />
      <CardContent>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              Changer le mot de passe
            </Typography>
            <Typography className={classes.secondaryHeading}>
              Nous vous conseillons d’utiliser un mot de passe sûr que vous
              n’utilisez nulle part ailleurs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccountPassword onSuccess={()=>setExpanded('')} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              Utiliser l’authentification à deux facteurs
            </Typography>
            <Typography className={classes.secondaryHeading}>
              Nous vous demanderons un code si nous remarquons une tentative de
              connexion depuis un appareil ou un navigateur non reconnu.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccountTwoFactor />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};
export default AccountSecurity;
