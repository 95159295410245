import React from 'react';
import TableRow from './table-row';
import { v4 as uuid } from 'uuid';
import TableCell from './table-cell';

type Props = {
  items: any[];
  columns: any[];
};

const TableRows: React.FC<Props> = ({ items, columns }) => {
  return (
    <>
      {items.map((item: any) => (
        <TableRow key={item.id || uuid()}>
          {columns.map((column) => (
            <TableCell
              key={column.key}
              styles={column.styles}
              width={column.width}
            >
              {column.valueFormatter
                ? column.valueFormatter(item)
                : item[column.key] || ''}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableRows;
