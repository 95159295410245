import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ResponsiblesApi } from 'src/api';
import ResponsibleForm from 'src/components/responsibles/form';
import { IResponsible } from 'src/types/responsible';

const CreateResponsible: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { partnerId } = useParams();
  const mutation = useMutation(ResponsiblesApi.create, {
    onSuccess: () => {
      toast.success('Responsable ajouté avec succés');
      queryClient
        .invalidateQueries(['partners', 'responsibles', partnerId])
        .catch(() => {});
      navigate(`/app/partners/${partnerId}`);
    },
    onError: () => {
      toast.error("une erreur est survenue lors de l'ajout");
    }
  });

  const onSubmit = (data: Partial<IResponsible>) => {
    mutation.mutate({ ...data, partner_id: parseInt(partnerId) });
  };

  return (
    <>
      <Helmet>
        <title>Création d'un responsable | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'un responsable</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <ResponsibleForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateResponsible;
