import { Box, Card, Container, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { OperationsApi } from 'src/api';
import OperationForm from 'src/components/operations/form';
import { IOperation } from 'src/types/operation';

const EditOperation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: operation, isLoading } = useQuery(
    ['operations', id],
    () => OperationsApi.get(id),
    {
      onError: () => {
        navigate('/app/operations');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation<
    IOperation,
    AxiosError,
    { id: string; body: Partial<IOperation> }
  >(OperationsApi.update, {
    onSuccess: () => {
      toast.success('Opération modifié avec succés');
      queryClient.invalidateQueries(['operations']).catch(() => {});
      navigate('/app/operations');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IOperation>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'une opération | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'une opération</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <OperationForm
                  onSubmit={onSubmit}
                  defaultValues={operation}
                  errors={mutation?.error?.response?.data}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditOperation;
