import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { InvoicesApi } from 'src/api';
import { InvoiceForm } from 'src/components/invoices';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';

const CreateInvoice: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(InvoicesApi.create, {
    onSuccess: (invoice: IInvoice) => {
      toast.success('Facture ajouté avec succés');
      queryClient
        .invalidateQueries(['invoices'])
        .then(() => {
          navigate(`/app/invoices/${invoice.id as string}`);
        })
        .catch(() => {});
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la création');
    }
  });

  const onSubmit = (data: Partial<IInvoiceForm>) => {
    mutation.mutate(data);
  };
  return (
    <>
      <Helmet>
        <title>Création d'une facture | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'une facture</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <InvoiceForm onSubmit={onSubmit} type="invoice" mode="CREATE" />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateInvoice;
