import qs from 'qs';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import { IPermission } from 'src/types';
import privateApi from './api';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IPermission>> => {
  const { data } = await privateApi.get(
    `/permissions?page=${page}&limit=${limit}`,
    {
      params: { query },
      paramsSerializer: qs.stringify
    }
  );
  return data;
};

export default {
  paginate,

};
