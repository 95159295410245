import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Paper, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import { useAuth } from 'src/contexts';
type DashboardNavbarProps = {
  onMobileNavOpen?: (...args: any[]) => any;
};
const DashboardNavbar: React.FC<DashboardNavbarProps> = ({
  onMobileNavOpen,
  ...rest
}) => {
  const { logout } = useAuth();

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/app/dashboard">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
          {/*<IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
          <IconButton color="inherit" component={RouterLink} to="/app/account">
            <AccountCircle />
          </IconButton>
          <IconButton color="inherit" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <IconButton color="inherit" component={RouterLink} to="/app/account">
            <AccountCircle />
          </IconButton>
          <IconButton color="inherit" onClick={logout}>
            <InputIcon />
          </IconButton>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default DashboardNavbar;
