import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PartnersApi } from 'src/api';
import PartnerForm from 'src/components/partners/form';
import { IPartner } from 'src/types/partner';

const CreatePartner: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(PartnersApi.create, {
    onSuccess: () => {
      toast.success('Partenaire ajouté avec succés');
      queryClient.invalidateQueries(['partners']).catch(() => {});
      navigate('/app/partners');
    },
    onError: () => {
      toast.error("une erreur est survenue lors de l'ajout");
    }
  });

  const onSubmit = (data: Partial<IPartner>) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Création d'un partenaire | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'un partenaire</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <PartnerForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreatePartner;
