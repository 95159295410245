import { Button, Grid, Typography } from '@material-ui/core';
type Props = {
  onClose: () => void;
};

const TwoFactorCompelted: React.FC<Props> = ({ onClose }) => {
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item>
        <Typography color="primary" align="center">
          Vous avez terminer l'activation et la vérification de
          l'authentification à deux facteurs. Dorénavant vous serai amené a
          entrer un code à 6 chiffres lors de l'authentification
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Button variant="contained" color="primary" onClick={onClose}>
          Fermer
        </Button>
      </Grid>
    </Grid>
  );
};
export default TwoFactorCompelted;
