import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    marginTop: 15,
    borderColor: 'black'
  },
  total: {
    backgroundColor: '#D6DBE5'
  }
});

const columns = [
  {
    key: 'header',
    name: 'Total TTC à Payer TND',
    width: '100%'
  }
];

type Props = {
  invoice: IInvoiceForm | IInvoice;
};

const InvoiceCostTTC: React.FC<Props> = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <>
        <TableRow>
          <TableCell width={'50%'}>Total HTVA</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {(invoice.operations_sub_total + invoice.pieces_sub_total).toFixed(
              3
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={'50%'}>TVA {invoice.tva}%</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {(
              ((invoice.operations_sub_total + invoice.pieces_sub_total) *
                invoice.tva) /
              100
            ).toFixed(3)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={'50%'}>Timbre Fiscal</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {invoice.stamp_price.toFixed(3)}
          </TableCell>
        </TableRow>
        <TableRow styles={styles.total}>
          <TableCell width={'50%'}>Total TTC</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {(
              (invoice.operations_sub_total + invoice.pieces_sub_total) *
                (1 + invoice.tva / 100) +
              invoice.stamp_price
            ).toFixed(3)}
          </TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceCostTTC;
