import { Button, CircularProgress, Typography } from '@material-ui/core';
import { usePDF } from '@react-pdf/renderer';
import { Dictionary } from 'lodash';
import { keyBy } from 'lodash-es';
import moment from 'moment';
import { memo } from 'react';
import { useQuery } from 'react-query';
import { OperationsApi, PiecesApi } from 'src/api';
import { IOperation, IPiece, IInvoiceForm, InvoiceType } from 'src/types';
import InvoiceDocument from './invoice-document/invoice-document';

type Props = {
  invoice: IInvoiceForm;
  type: InvoiceType;
};

const InvoiceButton: React.FC<Props> = ({ invoice, type }) => {
  const {
    data: operations,
    isLoading: isLoadingOperations,
    isSuccess: isSuccessOperations,
    isError: isErrorOperations
  } = useQuery(['invoice_operations'], () => OperationsApi.paginate(1, -1), {
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return keyBy(data?.data || [], 'id');
    }
  });
  const {
    data: pieces,
    isLoading: isLoadingPieces,
    isSuccess: isSuccessPieces,
    isError: isErrorPieces
  } = useQuery(['invoice_pieces'], () => PiecesApi.paginate(1, -1), {
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return keyBy(data?.data || [], 'id');
    }
  });

  return (
    <>
      {isLoadingOperations || isLoadingPieces ? (
        <CircularProgress />
      ) : isErrorOperations || isErrorPieces ? (
        <Typography>Veuillez vérifier votre connexion internet</Typography>
      ) : (
        isSuccessOperations &&
        isSuccessPieces &&
        operations &&
        pieces && <InvoiceViewButton {...{ invoice, pieces, operations, type }} />
      )}
    </>
  );
};

export default memo(InvoiceButton);

type InvoiceViewButtonProps = {
  invoice: IInvoiceForm;
  pieces: Dictionary<IPiece>;
  operations: Dictionary<IOperation>;
  type: InvoiceType;
};

const InvoiceViewButton: React.FC<InvoiceViewButtonProps> = memo(
  ({ invoice, pieces, operations, type }) => {
    const [instance] = usePDF({
      document: (
        <InvoiceDocument
          invoice={invoice}
          operations={operations || {}}
          pieces={pieces || {}}
          type={type}
          title={`${type === 'invoice' ? 'Facture' : 'Devis'} ${
            invoice.client?.name || ''
          } ${moment().format('yyyy-MM-DD')}`}
        />
      )
    });
    return (
      <Button
        href={instance.url || ''}
        target="_blank"
        variant="contained"
        disabled={instance.loading}
        className="text-center"
      >
        Voir {type === 'invoice' ? 'La Facture' : 'Le Devis'}
      </Button>
    );
  }
);
