export interface IClient {
  id: number;
  email: string;
  title: string;
  name: string;
  registration_number: string;
  phone: string;
  address: string;
  partner_id: number | null;
  responsible_id: number | null;
  created_at?: Date;
  updated_at?: Date;
}

export enum Title {
  M = 'M',
  Mme = 'Mme',
  Mlle = 'Mlle',
  Ste = 'Sté'
}

export const CLIENT_ENUMS = {
  TITLES: [
    {
      label: Title.M,
      value: Title.M
    },
    {
      label: Title.Mme,
      value: Title.Mme
    },
    {
      label: Title.Mlle,
      value: Title.Mlle
    },
    {
      label: Title.Ste,
      value: Title.Ste
    }
  ]
};
