import { Button, Container, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { IResponsible } from 'src/types/responsible';
import { Form, FormInput } from '../controls';
import FormPhoneInput from '../controls/phone-input';

type Props = {
  defaultValues?: IResponsible;
  onSubmit: (data: Partial<IResponsible>) => void;
};

const DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  position: ''
};
const ResponsibleForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInput label="Prénom" name="first_name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Nom" name="last_name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Email" name="email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormPhoneInput label="Téléphone" name="phone" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Poste" name="position" />
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default ResponsibleForm;
