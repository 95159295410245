import { Button, Container, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ICar } from 'src/types/car';
import { Form, FormInput } from '../controls';

type Props = {
  defaultValues?: ICar;
  onSubmit: (data: Partial<ICar>) => void;
};

const DEFAULT_VALUES = {
  make_name: '',
  model: '',
  type: ''
};
const CarForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container>
          <Grid container className="py-3 flex justify-center">
            <Grid item xs={12} sm={6}>
              <FormInput label="Marque" name="make_name" />
            </Grid>
          </Grid>
          <Grid container className="py-3 flex justify-center">
            <Grid item xs={12} sm={6}>
              <FormInput label="Modéle" name="model" />
            </Grid>
          </Grid>
          <Grid container className="py-3 flex justify-center">
            <Grid item xs={12} sm={6}>
              <FormInput label="Type" name="type" />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default CarForm;
