export interface ModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  closeReasons?: Array<ModalCloseReasons> 
}

export enum ModalCloseReasonEnum {
  BACKDROP_CLICK = 'backdropClick',
  ESCAPE_KEY_DOWN = 'escapeKeyDown'
}

export type ModalCloseReasons = 'backdropClick' | 'escapeKeyDown'