import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { ClientsCarsApi } from 'src/api';
import DataTable from 'src/components/datatable';
import { EditLink } from 'src/components/common';
import { Id } from 'src/types/common';
import { QueryOperatorsEnum } from 'src/types/paginate';

type Props = {
  id: Id;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    sortable: true,
    filterable: true,
    type: 'number'
  },
  {
    field: 'plate_number',
    headerName: 'Immatriculation',
    width: 150,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'car.make_name',
    headerName: 'Marque',
    width: 150,
    sortable: false,
    filterable: true,
    type: 'string',
    valueGetter: (params) => params.row.car.make_name
  },
  {
    field: 'car.model',
    headerName: 'Modéle',
    width: 150,
    sortable: false,
    filterable: true,
    type: 'string',
    valueGetter: (params) => params.row.car.model
  },
  {
    field: 'painting_ref',
    headerName: 'Ref peinture',
    width: 200,
    sortable: true,
    filterable: true,
    type: 'string'
  },
  {
    field: 'actions',
    headerName: ' ',
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => {
      return <EditLink to={`cars/edit/${params.id as string}`} />;
    }
  }
];

const ListClientCars: React.FC<Props> = ({ id }) => {
  return (
    <DataTable
      queryKey={['client-cars', id as string]}
      paginator={ClientsCarsApi.paginate}
      columns={columns}
      query={{
        criteria: [
          {
            field: 'owner_id',
            value: id,
            operator: QueryOperatorsEnum.equals
          }
        ]
      }}
    />
  );
};

export default ListClientCars;
