import { makeStyles, Tooltip } from '@material-ui/core';

type Props = {
  title: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 3,
      color: theme.palette.primary.contrastText,
      width: '30px',
      height: '30px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1rem 0.5rem'
    }
  };
});

const IconButton: React.FC<Props> = ({ title, onClick, children }) => {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <button className={classes.root} onClick={onClick}>
        {children}
      </button>
    </Tooltip>
  );
};

export default IconButton;
