import { GridFilterItem } from '@material-ui/data-grid';
import {
  IPaginateQueryCriteria,
  MUI_OPERATORS,
  QueryOperatorsEnum
} from 'src/types';

export const muiToEloquent = (
  items: Array<GridFilterItem>
): Array<IPaginateQueryCriteria> => {
  const criteria: Array<IPaginateQueryCriteria> = [];
  items.forEach((item) => {
    if (item.columnField && item.operatorValue && item.value != undefined) {
      const { operator, value } = operatorMatcher(
        item.value,
        item.operatorValue
      );
      criteria.push({
        field: item.columnField,
        value,
        operator
      });
    }
  });
  return criteria;
};

const operatorMatcher = (
  value: string,
  operator: string
): { value: string | null; operator: QueryOperatorsEnum } => {
  switch (operator) {
    case MUI_OPERATORS['!=']:
      return { value, operator: QueryOperatorsEnum.notEqual };
    case MUI_OPERATORS['=']:
      return { value, operator: QueryOperatorsEnum.equals };
    case MUI_OPERATORS['<']:
      return { value, operator: QueryOperatorsEnum.lt };
    case MUI_OPERATORS['<=']:
      return { value, operator: QueryOperatorsEnum.lte };
    case MUI_OPERATORS['>']:
      return { value, operator: QueryOperatorsEnum.gt };
    case MUI_OPERATORS['>=']:
      return { value, operator: QueryOperatorsEnum.gte };
    case MUI_OPERATORS['contains']:
      return { value: `%${value}%`, operator: QueryOperatorsEnum.LIKE };
    case MUI_OPERATORS['equals']:
      return { value, operator: QueryOperatorsEnum.equals };
    case MUI_OPERATORS['startsWith']:
      return { value: `${value}%`, operator: QueryOperatorsEnum.LIKE };
    case MUI_OPERATORS['endsWith']:
      return { value: `%${value}`, operator: QueryOperatorsEnum.LIKE };
    case MUI_OPERATORS['is']:
      return { value, operator: QueryOperatorsEnum.equals };
    case MUI_OPERATORS['not']:
      return { value, operator: QueryOperatorsEnum.notEqual };
    case MUI_OPERATORS['after']:
      return { value, operator: QueryOperatorsEnum.gt };
    case MUI_OPERATORS['onOrAfter']:
      return { value, operator: QueryOperatorsEnum.gte };
    case MUI_OPERATORS['before']:
      return { value, operator: QueryOperatorsEnum.lt };
    case MUI_OPERATORS['onOrBefore']:
      return { value, operator: QueryOperatorsEnum.lte };
    case MUI_OPERATORS['isEmpty']:
      return { value: null, operator: QueryOperatorsEnum.equals };
    case MUI_OPERATORS['isNotEmpty']:
      return { value: null, operator: QueryOperatorsEnum.notEqual };
  }
  return { value, operator: QueryOperatorsEnum.LIKE };
};
