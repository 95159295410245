import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { SettingsApi } from 'src/api';
import GlobalSettingsForm from 'src/components/settings/global-settings-form';
import { ISetting } from 'src/types/setting';

const settingsDefaultValuesBuilder = (settings?: Array<ISetting>) => {
  const defaultValues: { [key: string]: string } = {};
  settings?.forEach((setting) => {
    defaultValues[setting.name] = setting.value;
  });
  return defaultValues;
};
const SettingsView: React.FC = () => {
  const { data: settings, isLoading } = useQuery(['settings'], () =>
    SettingsApi.all()
  );

  return (
    <>
      <Helmet>
        <title>Paramètres | Pitstop</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Card>
            <CardHeader
              subheader="Gestion des paramétres globaux"
              title="Paramètres"
            />
            <Divider />
            <CardContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <GlobalSettingsForm
                  settings={settings || []}
                  defaultValues={settingsDefaultValuesBuilder(settings)}
                />
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
export default SettingsView;
