import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  Theme,
  Button
} from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PartnersApi } from 'src/api';
import { AddLink, TabPanel } from 'src/components/common';
import ListPartnerInvoices from 'src/components/partners/details/InvoicesList';
import ListPartnerQuotes from 'src/components/partners/details/QuotesList';
import ResponsiblesList from 'src/components/partners/details/ResponsiblesList';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: theme.palette.secondary.main
  },
  tabsIdicator: {
    color: 'white'
  },
  tab: {
    color: 'white !important'
  }
}));

const useTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  indicator: {
    backgroundColor: 'white !important',
    height: '4px !important'
  }
}));

const DetailsPartner: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const { data: partner, isLoading } = useQuery(
    ['partners', id],
    () => PartnersApi.get(id),
    {
      onError: () => {
        navigate('/app/partners');
        toast.error('une erreur est survenue');
      }
    }
  );

  return (
    <>
      <Helmet>
        <title>Détails du partenaire | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem', height: '100%' }}>
          {isLoading || partner === undefined ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  py: '1rem'
                }}
              >
                <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                  <Typography variant="h2">
                    Détails du partenaire {partner?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: '2rem'
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sx={{ color: 'black' }}>
                      <Typography variant="h4">Nom : {partner.name}</Typography>
                      <Typography variant="h4">
                        Matricule fiscale : {partner.registration_number}
                      </Typography>

                      <Typography variant="h4">
                        Adresse : {partner.address}
                      </Typography>
                      {partner.hourly_rate && (
                        <Typography variant="h4">
                          Taux horaire : {partner.hourly_rate}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item container xs={12} sx={{ py: 2 }}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link
                            to={`/app/partners/${id}/responsibles/create`}
                            target="_blank"
                          >
                            Ajouter un responsable
                          </Link>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link to="/app/invoices/create" target="_blank">
                            Créer une facture
                          </Link>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link to="/app/quotes/create" target="_blank">
                            Ajouter un devis
                          </Link>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  px: '2rem',
                  flexGrow: 1,
                  backgroundColor: 'white'
                }}
              >
                <AppBar position="static">
                  <Tabs
                    classes={tabsClasses}
                    value={value}
                    onChange={handleChange}
                    centered
                    variant="standard"
                  >
                    <Tab className={classes.tab} label="Responsables" />
                    <Tab className={classes.tab} label="Factures" />
                    <Tab className={classes.tab} label="Devis" />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <ResponsiblesList id={id} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ListPartnerInvoices id={id} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ListPartnerQuotes id={id} />
                </TabPanel>
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default DetailsPartner;
