import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UsersApi } from 'src/api';
import { AxiosError } from 'axios';
import { IUserPayload } from 'src/types/user';
import UserForm from 'src/components/users/form';

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation<any, AxiosError, any>(UsersApi.create, {
    onSuccess: () => {
      toast.success('Utilisateur crée avec succés');
      queryClient.invalidateQueries(['users']).catch(() => {});
      navigate('/app/users');
    },
    onError: (error) => {
      if (error.response?.data) {
        toast.error(error.response?.data);
      } else {
        toast.error('une erreur est survenue lors de la création');
      }
    }
  });

  const onSubmit = (data: Partial<IUserPayload>) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Créer un utilisateur | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Créer un utilisateur</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <UserForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateUser;
