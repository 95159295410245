import { IClientCar, IClientCarForm } from 'src/types/client-car';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import privateApi from './api';
import qs from 'qs';
import { Id } from 'src/types/common';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IClientCar>> => {
  const { data } = await privateApi.get(
    `/client_cars?page=${page}&limit=${limit}`,
    {
      params: { query },
      paramsSerializer: qs.stringify
    }
  );
  return data;
};

const get = async (id: Id): Promise<IClientCarForm> => {
  const { data } = await privateApi.get(`/client_cars/${id}`);
  return data;
};

const create = async (body: Partial<IClientCarForm>): Promise<IClientCar> => {
  const { data } = await privateApi.post('/client_cars', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<IClientCarForm>;
}): Promise<IClientCar> => {
  const { data } = await privateApi.patch(`/client_cars/${id}`, body);
  return data;
};

const remove = async (id: Id): Promise<void> => {
  const { data } = await privateApi.delete(`/client_cars/${id}`);
  return data;
};

export default {
  paginate,
  get,
  create,
  update,
  remove
};
