import { Box, Button, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';
import FormSelectAutoComplete from '../controls/select-autocomplete';
import {
  ClientsApi,
  ClientsCarsApi,
  PartnersApi,
  ResponsiblesApi
} from 'src/api';
import { IOptionItem } from 'src/types/form';
import { useQuery } from 'react-query';
import ClientDetails from './client-details';
import useDidMountEffect from 'src/hooks/use-did-mount-effect';
import { memo } from 'react';
import useFetchSettingByName from 'src/queries/use-fetch-setting-by-name';
import { QueryOperatorsEnum } from 'src/types/paginate';
import { IClient } from 'src/types';

const InvoiceInformations: React.FC = () => {
  const methods = useFormContext();

  const [clientId, clientCarId, partnerId] = methods.watch([
    'client_id',
    'client_car_id',
    'partner_id'
  ]);

  const { data: hourlyRateSetting } = useFetchSettingByName({
    name: 'hourly_rate',
    options: {
      onSuccess: (data) => {
        if (data) {
          methods.setValue('hourly_rate', parseFloat(data.value));
        }
      }
    }
  });

  const { data: clients, isLoading: clientsIsLoading } = useQuery(
    ['clients'],
    () => ClientsApi.paginate(1, -1)
  );

  const {
    data: clientCars,
    isLoading: clientCarsIsLoading,
    refetch: refetchClientCars
  } = useQuery(
    ['clients_cars', clientId],
    () =>
      ClientsCarsApi.paginate(1, -1, {
        criteria: [
          {
            field: 'owner_id',
            value: clientId,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      enabled: clientId !== undefined
    }
  );

  const { data: partners, isLoading: partnersIsLoading } = useQuery(
    ['partners'],
    () => PartnersApi.paginate(1, -1),
    {
      onSuccess: ({ data }) => {
        if (partnerId) {
          const partner = data.find((partner) => partner.id === partnerId);
          if (partner && partner.hourly_rate) {
            methods.setValue('hourly_rate', partner.hourly_rate);
          }
        } else if (hourlyRateSetting) {
          methods.setValue('hourly_rate', parseInt(hourlyRateSetting.value));
        }
      }
    }
  );

  const {
    data: responsibles,
    isLoading: responsiblesIsLoading,
    refetch: refetchResponsibles
  } = useQuery(
    ['responsibles', partnerId],
    () =>
      ResponsiblesApi.paginate(1, -1, {
        criteria: [
          {
            field: 'partner_id',
            value: partnerId,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      enabled: partnerId !== undefined,
      select: ({ data }) =>
        data.map((responsible): IOptionItem => {
          return {
            value: responsible.id,
            label: `${responsible.first_name} ${responsible.last_name}`
          };
        })
    }
  );

  useDidMountEffect(() => {
    if (clientId) {
      refetchClientCars().catch(() => {});
      // this is used for the invoice pdf to avoid fetching the client on the generation
      const client = clients?.data.find((c) => c.id === clientId);
      if (client?.partner_id) {
        methods.setValue('partner_id', client.partner_id);
      }
      methods.setValue('client', client || null);
    }else{
      methods.setValue('client', null);
      methods.setValue('partner_id', null);
      methods.setValue('responsible_id', null);

    }
    methods.setValue('client_car_id', null);
  }, [clientId]);

  useDidMountEffect(() => {
    if (clientCarId) {
      // this is used for the invoice pdf to avoid fetching the clientCar on the generation
      const clientCar = clientCars?.data.find((c) => c.id === clientCarId);
      methods.setValue('client_car', clientCar || null);
    }
  }, [clientCarId]);

  useDidMountEffect(() => {
    if (partnerId) {
      refetchResponsibles().catch(() => {});
      const partner = partners?.data.find(
        (partner) => partner.id === partnerId
      );
      if (partner && partner.hourly_rate) {
        methods.setValue('hourly_rate', partner.hourly_rate);
      }
    } else {
      if (hourlyRateSetting) {
        methods.setValue('hourly_rate', parseInt(hourlyRateSetting.value));
      }
    }
    const client: IClient | null = methods.getValues('client');
    if (client?.partner_id === partnerId && client?.responsible_id) {
      methods.setValue('responsible_id', client.responsible_id);
    } else {
      methods.setValue('responsible_id', null);
    }
  }, [partnerId]);

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={12} className=" flex justify-center">
          <FormSelectAutoComplete
            options={
              clients?.data.map((client): IOptionItem => {
                return {
                  value: client.id,
                  label: `${client.title} ${client.name} (${client.registration_number})`
                };
              }) || []
            }
            label="Client"
            name="client_id"
            isLoading={clientsIsLoading}
          />
          <Box className="pl-5 h-full">
            <Link className="h-full" to={'/app/clients/create'} target="_blank">
              <Button className="h-full" variant="contained" color="secondary">
                <AddIcon />
              </Button>
            </Link>
          </Box>
        </Grid>
        {clientId && (
          <Grid item xs={12}>
            <ClientDetails clientId={clientId} />
          </Grid>
        )}
      </Grid>
      {clientId && (
        <Grid container item xs={12}>
          <Grid item xs={12} className=" flex justify-center">
            <FormSelectAutoComplete
              options={
                clientCars?.data.map((clientCar): IOptionItem => {
                  return {
                    value: clientCar.id,
                    label: `${clientCar.car.make_name} ${clientCar.car.model} (${clientCar.plate_number})`
                  };
                }) || []
              }
              label="Voiture"
              name="client_car_id"
              isLoading={clientCarsIsLoading}
            />
            <Box className="pl-5 h-full">
              <Link
                className="h-full"
                to={`/app/clients/${clientId as string}/cars/create`}
                target="_blank"
              >
                <Button
                  className="h-full"
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon />
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12}>
        <Grid item xs={12} className=" flex justify-center">
          <FormSelectAutoComplete
            options={
              partners?.data.map((partner): IOptionItem => {
                return {
                  value: partner.id,
                  label: `${partner.name} ${
                    partner.hourly_rate !== null
                      ? `(Taux: ${partner.hourly_rate} DT)`
                      : ''
                  }`
                };
              }) || []
            }
            label="Partenaire"
            name="partner_id"
            isLoading={partnersIsLoading}
          />
          <Box className="pl-5 h-full">
            <Link
              className="h-full"
              to={`/app/partners/create`}
              target="_blank"
            >
              <Button className="h-full" variant="contained" color="secondary">
                <AddIcon />
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      {partnerId && (
        <Grid container item xs={12}>
          <Grid item xs={12} className=" flex justify-center">
            <FormSelectAutoComplete
              options={responsibles || []}
              label="Responsable"
              name="responsible_id"
              isLoading={responsiblesIsLoading}
            />
            <Box className="pl-5 h-full">
              <Link
                className="h-full"
                to={`/app/partners/${partnerId as string}/responsibles/create`}
                target="_blank"
              >
                <Button
                  className="h-full"
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon />
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(InvoiceInformations);
