import { NavLink as RouterLink } from 'react-router-dom';
import { Box, ListItem } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { Icon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    link: {
      '&$active, &:hover': {
        backgroundColor: theme.palette.text.primary,
        color: 'white'
      }
    },
    active: {}
  };
});

type NavItemProps = {
  href: string;
  icon?: Icon | SvgIconComponent;
  title?: string;
};

const NavItem: React.FC<NavItemProps> = ({
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Box
        component={RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          px: 2,
          textTransform: 'none',
          width: '100%',
          '& svg': {
            mr: 1
          }
        }}
        to={href}
        activeClassName={classes.active}
        className={classes.link}
      >
        {Icon && <Icon size="20" />}
        <span>{title}</span>
      </Box>
    </ListItem>
  );
};
export default NavItem;
