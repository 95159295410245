import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './table-header';
import TableRows from './table-rows';
import TableBlankSpace from './table-blank-space';
import TableFooter from './table-footer';
import { ReactNode } from 'react';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: 'black'
  }
});

type Props = {
  columns: any[];
  items: any[];
  tableRowsCount?: number;
  footerDescription?: string | ReactNode;
};
const Table: React.FC<Props> = ({
  items,
  columns,
  tableRowsCount = 20,
  footerDescription
}) => (
  <View>
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <TableRows items={items} columns={columns} />
      <TableBlankSpace
        columns={columns}
        rowsCount={tableRowsCount - items.length}
      />
    </View>
    {footerDescription && typeof footerDescription === 'string' ? (
      <TableFooter items={items} description={footerDescription} />
    ) : (
      footerDescription && <div>{footerDescription}</div>
    )}
  </View>
);

export default Table;
