import { Button, Typography, Box } from '@material-ui/core';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormInput } from '../controls';
import { useAuth } from 'src/contexts';

interface ITwoFactorInput {
  code: string;
}

const TwoFactorChallengeForm: React.FC = () => {
  const navigate = useNavigate();
  const { twoFactorChallenge } = useAuth();
  const methods = useForm<ITwoFactorInput>({
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string().length(6).required('Le code est obligatoire')
      })
    )
  });

  const onSubmit = (body: any) => {
    twoFactorChallenge(body)
      .then(() => {
        return navigate('/app/dashboard');
      })
      .catch(() => {
        return navigate('/login');
      });
  };

  const {
    formState: { isSubmitting }
  } = methods;

  return (
    <Form submitHandler={onSubmit} methods={methods}>
      <Box sx={{ mb: 3 }}>
        <img src="/static/logo.png" alt="logo" />
        <Typography color="textPrimary" variant="h2" align="center">
          Connexion
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
          align="center"
        >
          Entrer le code recu
        </Typography>
      </Box>
      <Box>
        <FormInput
          name="code"
          label="Code"
          required
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>

      <Box sx={{ py: 2 }}>
        <Button
          color="primary"
          disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Envoyer
        </Button>
      </Box>
    </Form>
  );
};

export default TwoFactorChallengeForm;
