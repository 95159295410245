import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert
} from '@material-ui/core';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { AuthenticationApi } from 'src/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormCheckBox, FormInput } from '../controls';
import { useAuth } from 'src/contexts';
import { useMutation } from 'react-query';

interface ILoginInput {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const mutation = useMutation(login, {
    onSuccess: (data: any) => {
      if (data.two_factor) {
        navigate('/two-factor-challenge');
      } else {
        navigate('/app/dashboard');
      }
    }
  });
  const methods = useForm<ILoginInput>({
    defaultValues: {
      email: '',
      password: '',
      remember: false
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })
    )
  });

  const onSubmit = (body: any) => {
    AuthenticationApi.csrfCookie()
      .then(() => {
        return mutation.mutate(body);
      })
      .catch(() => {});
  };

  return (
    <Form submitHandler={onSubmit} methods={methods}>
      <Box sx={{ mb: 3 }}>
        <img src="/static/logo.png" alt="logo" />
        <Typography color="textPrimary" variant="h2" align="center">
          Connexion
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
          align="center"
        >
          Connexion à la platforme interne
        </Typography>
      </Box>
      <Box>
        {mutation.isError && (
          <Alert severity="error">
            Veuillez vérifier vos informations puis réessayez
          </Alert>
        )}
        <FormInput
          name="email"
          label="Email"
          required
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <FormInput
          name="password"
          label="Mot de passe"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          type="password"
        />
        <FormCheckBox
          name="remember"
          label="Se souvenir de moi"
          color="primary"
        />
      </Box>

      <Box sx={{ py: 2 }}>
        <Button
          color="primary"
          disabled={mutation.isLoading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {mutation.isLoading && <CircularProgress />}
          Se connecter
        </Button>
      </Box>
    </Form>
  );
};

export default LoginForm;
