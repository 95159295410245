import { Id } from 'src/types/common';
import { ISetting } from 'src/types/setting';
import privateApi from './api';

const all = async (): Promise<ISetting[]> => {
  const { data } = await privateApi.get('/settings');
  return data;
};

const get = async (id: Id): Promise<ISetting> => {
  const { data } = await privateApi.get(`/settings/${id}`);
  return data;
};

const getByName = async (name: Id): Promise<ISetting> => {
  const { data } = await privateApi.get(`/settings/by-name/${name}`);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<ISetting>;
}): Promise<ISetting> => {
  const { data } = await privateApi.patch(`/settings/${id}`, body);
  return data;
};

export default {
  all,
  get,
  getByName,
  update
};
