import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { QuotesApi } from 'src/api';
import { InvoiceForm } from 'src/components/invoices';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';

const DuplicateQuote: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: quote, isLoading } = useQuery(
    ['quotes', id],
    () => QuotesApi.get(id),
    {
      onError: () => {
        navigate('/app/quotes');
        toast.error('une erreur est survenue');
      }
    }
  );
  const mutation = useMutation(QuotesApi.create, {
    onSuccess: (quote: IInvoice) => {
      toast.success('Devis ajouté avec succés');
      queryClient
        .invalidateQueries(['invoices'])
        .then(() => {
          navigate(`/app/quotes/${quote.id as string}`);
        })
        .catch(() => {});
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la création');
    }
  });

  const onSubmit = (data: Partial<IInvoiceForm>) => {
    mutation.mutate(data);
  };
  return (
    <>
      <Helmet>
        <title>Duplication d'un devis | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">
              Duplication d'un devis
            </Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            {isLoading ? (
              'Loading ...'
            ) : (
              <InvoiceForm
                onSubmit={onSubmit}
                defaultValues={quote}
                type="quote"
                mode="DUPLICATE"
              />
            )}
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default DuplicateQuote;
