import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { InvoicesApi } from 'src/api';
import { InvoiceViewer } from 'src/components/invoices';

const DetailsInvoice: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: invoice, isLoading } = useQuery(
    ['invoices', id],
    () => InvoicesApi.get(id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: () => {
        navigate('/app/invoices');
        toast.error('une erreur est survenue');
      }
    }
  );

  return (
    <>
      <Helmet>
        <title>Facture | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">
              Facture{' '}
              {`${invoice?.client?.name || ''} ${moment(invoice?.date ?? '').format(
                'yyyy-MM-DD'
              )}`}
            </Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              invoice && (
                <Box display="flex" justifyContent="center" marginTop="1rem">
                  <InvoiceViewer {...{ invoice, type: 'invoice' }} />
                </Box>
              )
            )}
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default DetailsInvoice;
