import { Link } from 'react-router-dom';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';

type Props = {
  to: string;
  target?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 3,
      color: theme.palette.primary.contrastText,
      width: '30px',
      height: '30px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1rem 0.5rem'
    }
  };
});

const DetailsLink: React.FC<Props> = ({ to, target = '_self' }) => {
  const classes = useStyles();
  return (
    <Tooltip title="Détails">
      <Link className={classes.root} to={to} target={target}>
        <SearchIcon className="w-full h-full" />
      </Link>
    </Tooltip>
  );
};

export default DetailsLink;
