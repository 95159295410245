import { StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import {
  IInvoice,
  IInvoiceForm,
  INVOICE_PAYEMENT_MODES_LABELS
} from 'src/types/invoice';
import { ensureNumberMinLength } from 'src/utils';
import { formatClient } from 'src/utils/users';

const styles = StyleSheet.create({
  headerInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInfoBox: {
    display: 'flex',
    flexDirection: 'row',
    border: '1.5pt solid black',
    width: '100%'
  },
  invoiceInfoLabels: {
    padding: 2,
    backgroundColor: '#44546A',
    color: 'white',
    width: 100
  },
  invoiceInfoValues: {
    padding: 2,
    color: 'black'
  }
});

type Props = {
  invoice: IInvoiceForm | IInvoice;
  type: 'invoice' | 'quote';
};

const InvoiceHeaderInfos: React.FC<Props> = ({ invoice, type }) => {
  return (
    <View style={styles.headerInfoContainer}>
      <View style={{ ...styles.headerInfoBox, marginRight: 10 }}>
        <View style={styles.invoiceInfoLabels}>
          <Text>{type === 'invoice' ? 'Facture' : 'Devis'} n°</Text>
          <Text>Date</Text>
          <Text>Mode de Paiement</Text>
        </View>
        <View style={styles.invoiceInfoValues}>
          <Text>
            {invoice.internal_id && 'creation_year' in invoice
              ? `${invoice.creation_year}/${ensureNumberMinLength(
                  invoice.internal_id,
                  3
                )}`
              : 'YYYY/XXX'}
          </Text>
          <Text>{moment(invoice.date).format('dddd DD MMMM YYYY')}</Text>
          <Text>{INVOICE_PAYEMENT_MODES_LABELS[invoice.payment_mode]}</Text>
        </View>
      </View>
      <View style={{ ...styles.headerInfoBox, marginLeft: 10 }}>
        <View style={styles.invoiceInfoLabels}>
          <Text>{type === 'invoice' ? 'Facture' : 'Devis'} Pour</Text>
          <Text>Matr. Fiscal</Text>
          <Text>Adresse</Text>
        </View>
        <View style={styles.invoiceInfoValues}>
          <Text>{formatClient(invoice.client)}</Text>
          <Text>{invoice.client?.registration_number}</Text>
          <Text>{invoice.client?.address}</Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceHeaderInfos;
