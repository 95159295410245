import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import TwoFactorChallengeForm from 'src/components/authentication/two-factor-challenge-form';

const TwoFactorChallenge: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Login | Pitstop</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <TwoFactorChallengeForm />
        </Container>
      </Box>
    </>
  );
};
export default TwoFactorChallenge;
