import { CircularProgress, Typography } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import { keyBy } from 'lodash-es';
import moment from 'moment';
import { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { OperationsApi, PiecesApi } from 'src/api';
import { IInvoiceForm, InvoiceType } from 'src/types';
import { InvoiceButton } from '.';
import InvoiceDocument from './invoice-document/invoice-document';

type Props = {
  invoice: IInvoiceForm;
  type: InvoiceType;
};

const InvoiceViewer: React.FC<Props> = ({ invoice, type }) => {
  const {
    data: operations,
    isLoading: isLoadingOperations,
    isSuccess: isSuccessOperations,
    isError: isErrorOperations
  } = useQuery(['invoice_operations'], () => OperationsApi.paginate(1, -1), {
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return keyBy(data?.data || [], 'id');
    }
  });
  const {
    data: pieces,
    isLoading: isLoadingPieces,
    isSuccess: isSuccessPieces,
    isError: isErrorPieces
  } = useQuery(['invoice_pieces'], () => PiecesApi.paginate(1, -1), {
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return keyBy(data?.data || [], 'id');
    }
  });

  return (
    <>
      {isLoadingOperations || isLoadingPieces ? (
        <CircularProgress />
      ) : isErrorOperations || isErrorPieces ? (
        <Typography>Veuillez vérifier votre connexion internet</Typography>
      ) : (
        isSuccessOperations &&
        isSuccessPieces &&
        operations &&
        pieces &&
        (isMobile ? (
          <InvoiceButton {...{invoice, type}} />
        ) : (
          <PDFViewer width="100%" height="1000px">
            <InvoiceDocument
              invoice={invoice}
              operations={operations || {}}
              pieces={pieces || {}}
              type={type}
              title={`${type === 'invoice' ? 'Facture' : 'Devis'} ${
                invoice.client?.name || ''
              } ${moment().format('yyyy-MM-DD')}`}
            />
          </PDFViewer>
        ))
      )}
    </>
  );
};

export default memo(InvoiceViewer);
