import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black';
const styles = StyleSheet.create({
  row: {
    marginTop: 15,
    marginLeft: '40%',
    flexDirection: 'row',
    borderColor: borderColor,
    borderWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
    width: '60%'
  },
  description: {
    width: '70%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingTop: 4,
    height: '100%',
    backgroundColor: '#44546A',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    lineHeight: '100%'
  },
  total: {
    width: '30%',
    paddingRight: 8,
    paddingTop: 4,
    height: '100%',
    display: 'flex',
    flexDirection: 'row-reverse'
  }
});

type Props = {
  items: any;
  description: string;
};
const InvoiceTableFooter: React.FC<Props> = ({ items, description }) => {
  const total = items
    .map((item: any) => item.total)
    .reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue,
      0
    );
  return (
    <View style={styles.row}>
      <Text style={styles.description}>{description}</Text>
      <View style={styles.total}>
        <Text>{Number.parseFloat(total).toFixed(3)}</Text>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
