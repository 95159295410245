import { Box, Container, Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { RolesApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import { AddLink, DeleteButton, EditLink } from 'src/components/common';
import { useAuth } from 'src/contexts';

const ListRoles: React.FC = () => {
  const { can } = useAuth();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      sortable: true,
      filterable: false
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 200,
      sortable: true,
      filterable: false
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 200,
      sortable: true,
      filterable: false
    },

    {
      field: 'actions',
      headerName: ' ',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        return (
          <Box className="flex justify-center w-full">
            {can('roles.update') && (
              <EditLink to={`edit/${params.id as string}`} />
            )}
            {can('roles.delete') && (
              <DeleteButton
                id={id}
                remove={RolesApi.remove}
                queryKey={'roles'}
              />
            )}
          </Box>
        );
      }
    }
  ];
  
  return (
    <>
      <Helmet>
        <title>Rôles | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Rôles
            {can('roles.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['roles']}
            paginator={RolesApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListRoles;
