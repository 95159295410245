import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import { IPiece } from 'src/types/piece';
import privateApi from './api';
import qs from 'qs';
import { Id } from 'src/types/common';

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IPiece>> => {
  const { data } = await privateApi.get(`/pieces?page=${page}&limit=${limit}`, {
    params: { query },
    paramsSerializer: qs.stringify
  });
  return data;
};

const get = async (id: Id): Promise<IPiece> => {
  const { data } = await privateApi.get(`/pieces/${id}`);
  return data;
};

const create = async (body: Partial<IPiece>): Promise<IPiece> => {
  const { data } = await privateApi.post('/pieces', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: Id;
  body: Partial<IPiece>;
}): Promise<IPiece> => {
  const { data } = await privateApi.patch(`/pieces/${id}`, body);
  return data;
};

const remove = async (id: Id): Promise<void> => {
  const { data } = await privateApi.delete(`/pieces/${id}`);
  return data;
};

export default {
  paginate,
  get,
  create,
  update,
  remove
};
