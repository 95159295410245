import { GridSortItem } from '@material-ui/data-grid';

export interface IPaginationResult<T> {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: IPaginationLink[];
  next_page_url?: string;
  path: string;
  per_page: number;
  prev_page_url?: string;
  to: number;
  total: number;
  data: T[];
}

export interface IPaginationLink {
  url?: string;
  active: boolean;
  label: string;
}

export interface IPaginateQuery {
  criteria?: Array<IPaginateQueryCriteria>;
  sort?: Array<GridSortItem>;
}

export interface IPaginateQueryCriteria {
  field: string;
  value: unknown;
  operator: QueryOperatorsEnum;
}

export enum QueryOperatorsEnum {
  equals = '=',
  lt = '<',
  gt = '>',
  lte = '<=',
  gte = '>=',
  notEqual = '<>',
  LIKE = 'LIKE',
  NOTLIKE = 'NOTLIKE',
  BETWEEN = 'BETWEEN',
  ILIKE = 'ILIKE'
}

export const MUI_OPERATORS = {
  //number
  '=': '=',
  '!=': '!=',
  '>': '>',
  '<': '<',
  '>=': '>=',
  '<=': '<=',
  //string
  contains: 'contains',
  equals: 'equals',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  //date
  is: 'is', // boolean
  not: 'not',
  after: 'after',
  onOrAfter: 'onOrAfter',
  before: 'before',
  onOrBefore: 'onOrbefore',
  //common
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty'
};
