import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AuthenticationApi } from 'src/api';
import { Form, FormInput } from 'src/components/controls';
import * as Yup from 'yup';

interface ITwoFactorInput {
  code: string;
}

const TwoFactorChallengeVerification: React.FC = () => {
  const mutation = useMutation((body: ITwoFactorInput) =>
    AuthenticationApi.twoFactorChallengeVerify(body)
  );
  const methods = useForm<ITwoFactorInput>({
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string().length(6).required('Le code est obligatoire')
      })
    )
  });

  const onSubmit = (body: ITwoFactorInput) => {
    mutation.mutate(body);
  };
  return (
    <Form submitHandler={onSubmit} methods={methods}>
      <Grid container spacing={3} justifyItems="center">
        <Grid item xs={12}>
          <Typography color="primary" align="center">
            Entrer le code reçu dans l'application d'authentification pour
            vérifier que tout fonctionne bien
          </Typography>
        </Grid>
        {mutation.isSuccess && (
          <Grid item xs={12}>
            <Alert severity="success">Code vérifier avec succés !</Alert>
          </Grid>
        )}
        {mutation.isError && (
          <Grid item xs={12}>
            <Alert severity="error">Code vérifier avec succés !</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormInput
            name="code"
            label="Code"
            required
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            disabled={mutation.isSuccess}
          >
            Vérifier
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
export default TwoFactorChallengeVerification;
