import { Button, Container, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { PiecesApi } from 'src/api';
import { IOptionItem } from 'src/types/form';
import { QueryOperatorsEnum } from 'src/types/paginate';
import { IPiece } from 'src/types/piece';
import { Form, FormCheckBox, FormInput } from '../controls';
import FormSelect from '../controls/select';

type Props = {
  defaultValues?: IPiece;
  onSubmit: (data: Partial<IPiece>) => void;
  errors?: any;
};

const DEFAULT_VALUES = {
  name: '',
  repairable: false,
  replaceable: false,
  paintable: false,
  repairable_pieces: [],
  replaceable_pieces: [],
  paintable_pieces: []
};
const PieceForm: React.FC<Props> = ({ defaultValues, onSubmit, errors }) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  const { setError } = methods;

  useEffect(() => {
    if (errors?.validations) {
      for (const field in errors.validations) {
        setError(field as keyof IPiece, {
          type: 'manual',
          message: errors.validations[field].join('\n')
        });
      }
    }
  }, [errors, setError]);

  const [repairable, replaceable, paintable] = methods.watch([
    'repairable',
    'replaceable',
    'paintable'
  ]);

  const { data: repairablePieces } = useQuery(
    ['repairable_pieces'],
    () =>
      PiecesApi.paginate(1, -1, {
        criteria: [
          {
            field: 'repairable',
            value: true,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      select: ({ data }) =>
        data.map((piece): IOptionItem => {
          return {
            value: piece.id,
            label: piece.name
          };
        })
    }
  );
  const { data: replaceablePieces } = useQuery(
    ['replaceable_pieces'],
    () =>
      PiecesApi.paginate(1, -1, {
        criteria: [
          {
            field: 'replaceable',
            value: true,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      select: ({ data }) =>
        data.map((piece): IOptionItem => {
          return {
            value: piece.id,
            label: piece.name
          };
        })
    }
  );
  const { data: paintablePieces } = useQuery(
    ['paintable_pieces'],
    () =>
      PiecesApi.paginate(1, -1, {
        criteria: [
          {
            field: 'paintable',
            value: true,
            operator: QueryOperatorsEnum.equals,
          }
        ]
      }),
    {
      select: ({ data }) =>
        data.map((piece): IOptionItem => {
          return {
            value: piece.id,
            label: piece.name
          };
        })
    }
  );

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormInput label="Nom" name="name" />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <FormCheckBox label="Réparable" name="repairable" />
            </Grid>
            {repairable && (
              <Grid item xs={8}>
                <FormSelect
                  options={repairablePieces || []}
                  label="Piéces réparables"
                  name="repairable_pieces"
                  multiple
                />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <FormCheckBox label="Remplaçable" name="replaceable" />
            </Grid>
            {replaceable && (
              <Grid item xs={8}>
                <FormSelect
                  options={replaceablePieces || []}
                  label="Piéces remplacables"
                  name="replaceable_pieces"
                  multiple
                />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <FormCheckBox label="Peut être peint" name="paintable" />
            </Grid>
            {paintable && (
              <Grid item xs={8}>
                <FormSelect
                  options={paintablePieces || []}
                  label="Piéces pouvant être peint"
                  name="paintable_pieces"
                  multiple
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => methods.reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default PieceForm;
