export const ensureNumberMinLength = (
  value: number,
  minLength: number
): string => {
  const stringValue = value.toString();
  if (stringValue.length < minLength) {
    return (
      new Array(minLength - stringValue.length + 1).join('0') + stringValue
    );
  }

  return stringValue;
};
