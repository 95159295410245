import { Form, FormInput } from 'src/components/controls';
import { useForm } from 'react-hook-form';
import { Button, Grid, Typography } from '@material-ui/core';
import { ISetting } from 'src/types/setting';
import { useMutation } from 'react-query';
import { SettingsApi } from 'src/api';
import { Id } from 'src/types/common';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

type Props = {
  settings: Array<ISetting>;
  defaultValues: any;
};

const GlobalSettingsForm: React.FC<Props> = ({ settings, defaultValues }) => {
  const confirm = useConfirm();
  const methods = useForm({ defaultValues: defaultValues || {} });

  const mutation = useMutation(SettingsApi.update, {
    onSuccess: () => {
      toast.success('Paramètre mis à jour avec succés');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la mise à jour');
    }
  });

  const handleSettingChange = (id: Id, name: string) => {
    confirm({
      title: 'Êtes-vous sûr?',
      description: `Cela changera définitivement la  valeur de ce paramètre`
    })
      .then(() => {
        mutation.mutate({
          id,
          body: {
            value: methods.getValues(name)
          }
        });
      })
      .catch(() => {});
  };

  return (
    <Form methods={methods} submitHandler={() => {}}>
      <Grid container spacing={3}>
        {settings.map((setting) => (
          <Grid
            key={setting.id}
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={2}>
              <Typography>{setting.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormInput fullWidth name={setting.name} type={setting.type} />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => handleSettingChange(setting.id, setting.name)}
              >
                Enregistrer
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => methods.setValue(setting.name, setting.value)}
              >
                Réinitialiser
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Form>
  );
};

export default GlobalSettingsForm;
