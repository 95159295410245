import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { SettingsApi } from 'src/api';
import { ISetting } from 'src/types/setting';

const useFetchSettingByName = ({
  name,
  options
}: {
  name: string;
  options?: UseQueryOptions<ISetting, AxiosError>;
}): UseQueryResult<ISetting, AxiosError> => {
  return useQuery<ISetting, AxiosError>(
    ['settings', name],
    () => SettingsApi.getByName(name),

    {
      ...options,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );
};

export default useFetchSettingByName;
