/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from './schemas';
import { Form, FormInput } from '../controls';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { UsersApi } from '../../api';

type Props = {
  onSuccess?: () => void;
};
const AccountPassword: React.FC<Props> = ({ onSuccess = () => {} }) => {
  const methods = useForm({
    defaultValues: {
      old_password: '',
      password: '',
      password_confirmation: ''
    },
    resolver: yupResolver(updatePasswordSchema),
    reValidateMode: 'onChange'
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(UsersApi.updatePassword, {
    onSuccess: () => {
      toast.success('Mot de passe modifié avec succés');
      queryClient.invalidateQueries(['authenticated-user']).catch(() => {});
      methods.reset();
      onSuccess();
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la mise à jour');
    }
  });

  const onSubmit = (values: any) => {
    mutation.mutate(values);
  };

  return (
    <Form methods={methods} submitHandler={onSubmit}>
      <Grid container alignContent="center" spacing={3}>
        <Grid item xs={12}>
          <FormInput name="old_password" type="password" label="Mot de passe" />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="password"
            type="password"
            label="Nouveau mot de passe"
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="password_confirmation"
            type="password"
            label="Confirmation du mot de passe"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" marginTop="1rem">
        <Button type="submit" variant="contained" color="primary">
          Enregistrer
        </Button>
      </Grid>
    </Form>
  );
};

export default AccountPassword;
