import {
  AppBar,
  Box,
  Card,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClientsApi } from 'src/api';
import ListClientCars from 'src/components/clients/details/CarsList';
import { useState, ChangeEvent } from 'react';
import ListClientInvoices from 'src/components/clients/details/InvoicesList';
import ListClientQuotes from 'src/components/clients/details/QuotesList';
import { TabPanel } from 'src/components/common';


const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: theme.palette.secondary.main
  },
  tabsIdicator: {
    color: 'white'
  },
  tab: {
    color: 'white !important'
  }
}));

const useTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  indicator: {
    backgroundColor: 'white !important',
    height: '4px !important'
  }
}));

const DetailsClient: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: client, isLoading } = useQuery(
    ['clients', id],
    () => ClientsApi.get(id),
    {
      onError: () => {
        navigate('/app/clients');
        toast.error('une erreur est survenue');
      }
    }
  );

  /*const mutation = useMutation(CarsApi.update, {
    onSuccess: () => {
      toast.success('Voiture modifié avec succés');
      queryClient.invalidateQueries(['cars']).catch(() => {});
      navigate('/app/cars');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });*/

  /*const onSubmit = (data: Partial<ICar>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };*/
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Détails du client | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem', height: '100%' }}>
          {isLoading || client === undefined ? (
            'Loading ...'
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  py: '1rem'
                }}
              >
                <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                  <Typography variant="h2">
                    Détails du client {client?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: '2rem'
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sx={{ color: 'black' }}>
                      <Typography variant="h4">
                        Nom : {`${client.title} ${client.name}`}
                      </Typography>
                      <Typography variant="h4">
                        Matricule fiscale : {client.registration_number}
                      </Typography>
                      <Typography variant="h4">
                        Email : {client.email}
                      </Typography>
                      <Typography variant="h4">
                        Téléphone : {client.phone}
                      </Typography>
                      <Typography variant="h4">
                        Adresse : {client.address}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sx={{ py: 2 }}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link to={`cars/create`}>Ajouter une voiture</Link>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link to="/app/invoices/create">
                            Créer une facture
                          </Link>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button variant="contained">
                          <Link to="/app/quotes/create">Ajouter un devis</Link>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    px: '2rem',
                    flexGrow: 1,
                    backgroundColor: 'white'
                  }}
                >
                  <AppBar position="static">
                    <Tabs
                      classes={tabsClasses}
                      value={value}
                      onChange={handleChange}
                      centered
                      variant="standard"
                    >
                      <Tab className={classes.tab} label="Voitures" />
                      <Tab className={classes.tab} label="Factures" />
                      <Tab className={classes.tab} label="Devis" />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <ListClientCars id={client.id} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <ListClientInvoices id={client.id} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <ListClientQuotes id={client.id} />
                  </TabPanel>
                </Box>
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default DetailsClient;
