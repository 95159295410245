import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoiceForm, IInvoice } from 'src/types/invoice';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: 'black'
  }
});

const columns = [
  {
    key: 'header',
    name: 'Coûts HT en TND',
    width: '100%'
  }
];

type Props = {
  invoice: IInvoiceForm | IInvoice;
};

const InvoiceCostHT: React.FC<Props> = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <>
        <TableRow>
          <TableCell width={'50%'}>Main D'œuvre</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {invoice.operations_sub_total.toFixed(3)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={'50%'}>Pièces & Fourniture</TableCell>
          <TableCell width={'50%'} styles={{ textAlign: 'right' }}>
            {invoice.pieces_sub_total.toFixed(3)}
          </TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceCostHT;
