import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import Cookies from 'js-cookie';
import { AuthenticationApi, UsersApi } from 'src/api';
import { IUser } from 'src/types/user';
import { useFetchUser } from 'src/queries';
import { Navigate } from 'react-router';

interface IAuthContext {
  user: IUser | null;
  isUserLoading: boolean;
  login: (form: any) => Promise<IUser | null>;
  twoFactorChallenge: (form: any) => Promise<any>;
  logout: () => void;
  enableTwoFactor: () => Promise<IUser | null>;
  disableTwoFactor: () => Promise<IUser | null>;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  setIsUserLoading: React.Dispatch<React.SetStateAction<boolean>>;
  can(permission: string): boolean;
  canNavigateTo(permission: string, page: ReactNode): ReactNode;
}

const INITIAL_CONTEXT: IAuthContext = {
  user: null,
  isUserLoading: true,
  login: () => Promise.resolve(null),
  twoFactorChallenge: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  enableTwoFactor: () => Promise.resolve(null),
  disableTwoFactor: () => Promise.resolve(null),
  setUser: () => Promise.resolve(),
  setIsUserLoading: () => Promise.resolve(),
  can: () => false,
  canNavigateTo: () => null
};

const AuthContext = createContext<IAuthContext>(INITIAL_CONTEXT);

const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<IUser | null>(INITIAL_CONTEXT.user);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(
    INITIAL_CONTEXT.isUserLoading
  );

  const login = async (form: any) => {
    const loginResult = await AuthenticationApi.login(form)
      .then(async (data) => {
        if (!data.two_factor) {
          await UsersApi.user()
            .then((user) => {
              setUser(user);
            })
            .catch(() => {});
        }
        return data;
      })
    
    return loginResult;
  };

  const twoFactorChallenge = async (form: any) => {
    const twoFactorResult = await AuthenticationApi.twoFactorChallenge(
      form
    ).then(async () => {
      await UsersApi.user()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {})
      
    });
    return twoFactorResult;
  };
  const logout = () => {
    AuthenticationApi.logout()
      .then(() => {
        setUser(null);
        Cookies.remove('pitstop_session');
        Cookies.remove('XSRF-TOKEN');
      })
      .catch(() => {});
  };

  const enableTwoFactor = async () => {
    return await UsersApi.enableTwoFactor()
      .then(async () => {
        const newUserContext = await UsersApi.user();
        setUser(newUserContext);
        return newUserContext;
      })
      .catch(() => {
        return null;
      });
  };
  const disableTwoFactor = async () => {
    return await UsersApi.disableTwoFactor()
      .then(async () => {
        const newUserContext = await UsersApi.user();
        setUser(newUserContext);
        return newUserContext;
      })
      .catch(() => {
        return null;
      });
  };

  const can = (permission: string): boolean => {
    return user?.roles_names.includes('super_admin') ||
      (user?.permissions_names || []).find((p) => p === permission)
      ? true
      : false;
  };

  const canNavigateTo = (permission: string, page: ReactNode): ReactNode => {
    return user?.roles_names.includes('super_admin') ||
      (user?.permissions_names || []).find((p) => p === permission) ? (
      page
    ) : (
      <Navigate to="/" />
    );
  };

  

  const { data, isError, isSuccess, refetch, isLoading } = useFetchUser({
    options: {
      retry: false,
      onError: (res) => {
        if (res.response?.status === 401) {
          logout();
        }
      }
    }
  });
  const isSettled = isError || isSuccess;

  useEffect(() => {
    refetch().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUserLoading(isLoading);
  }, [isLoading]);

  useLayoutEffect(() => {
    if (isSettled) {
      if (!isError && data) {
        setUser(data);
      } else {
        setUser(null);
      }
    }
  }, [isSettled, isError, data]);

  const memoedValue = useMemo(
    () => ({
      user,
      setUser,
      isUserLoading,
      setIsUserLoading,
      login,
      twoFactorChallenge,
      logout,
      enableTwoFactor,
      disableTwoFactor,
      can,
      canNavigateTo
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isUserLoading]
  );
  
  return <AuthContext.Provider value={memoedValue} {...props} />;
};

const useAuth = (): IAuthContext => {
  const authContext = useContext(AuthContext);

  return authContext;
};

export { AuthProvider, useAuth };
