import { Box, Card, Container, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { RolesApi } from 'src/api';
import RoleForm from 'src/components/roles/form';
import { IRole } from 'src/types';

const EditRole: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: role, isLoading } = useQuery(
    ['roles', id],
    () => RolesApi.get(id),
    {
      onError: () => {
        navigate('/app/roles');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation<
    IRole,
    AxiosError,
    { id: string; body: Partial<IRole> }
  >(RolesApi.update, {
    onSuccess: () => {
      toast.success('Rôle modifié avec succés');
      queryClient.invalidateQueries(['roles']).catch(() => {});
      navigate('/app/roles');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IRole>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'un rôle | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'un rôle</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <RoleForm onSubmit={onSubmit} defaultValues={role} />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditRole;
