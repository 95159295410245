import ReactPDF, {
  Document,
  Page,
  StyleSheet,
  View
} from '@react-pdf/renderer';
import InvoicePage from './invoice-page';
import Table from './table/table';
import TableFooter from './table/table-footer';
import InvoiceSummary from './invoice-summary';
import {
  IInvoice,
  IInvoiceForm,
  IInvoiceOperation,
  IInvoicePiece
} from 'src/types/invoice';
import { IPiece } from 'src/types/piece';
import { IOperation } from 'src/types/operation';
import { Dictionary } from 'lodash';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 18,
    paddingRight: 18,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  pageView: {
    border: '1.5pt solid black',
    width: '100%',
    height: '100%',
    paddingBottom: 5
  }
});

const operationsColumns = [
  {
    key: 'operation_label',
    name: 'Opération / Libellé',
    width: '60%'
  },
  {
    key: 'duration',
    name: 'Durée (H)',
    width: '12%',
    styles: {
      textAlign: 'right'
    }
  },
  {
    key: 'hourly_rate',
    name: 'Taux',
    width: '13%',
    styles: {
      textAlign: 'right'
    },
    valueFormatter: (item: IInvoiceOperation) => {
      return (+item.hourly_rate).toFixed(3);
    }
  },
  {
    key: 'total',
    name: 'Prix HT',
    width: '15%',
    styles: {
      textAlign: 'right'
    },
    valueFormatter: (item: IInvoiceOperation) => {
      return item.total.toFixed(3);
    }
  }
];

const piecesColumns = [
  {
    key: 'reference',
    name: 'Référence',
    width: '25%'
  },
  {
    key: 'piece_name',
    name: 'Désignation Pièce',
    width: '35%'
  },
  {
    key: 'quantity',
    name: 'QTE',
    width: '10%',
    styles: {
      textAlign: 'right'
    }
  },
  {
    key: 'unit_price',
    name: 'PU',
    width: '15%',
    styles: {
      textAlign: 'right'
    },
    valueFormatter: (item: IInvoicePiece) => {
      return (+item.unit_price).toFixed(3);
    }
  },
  {
    key: 'total',
    name: 'Prix HT',
    width: '15%',
    styles: {
      textAlign: 'right'
    },
    valueFormatter: (item: IInvoicePiece) => {
      return item.total.toFixed(3);
    }
  }
];

const operationsBuilder = (
  ioperations: Array<IInvoiceOperation>,
  pieces: Dictionary<IPiece>,
  operations: Dictionary<IOperation>
) => {
  return ioperations.map((io) => {
    return {
      operation_label: `${operations[io.operation_id].label} ${
        pieces[io.piece_id].name
      }`,
      ...io
    };
  });
};

const piecesBuilder = (
  ipieces: Array<IInvoicePiece>,
  pieces: Dictionary<IPiece>
) => {
  return ipieces.map((ip) => {
    return {
      piece_name: pieces[ip.piece_id].name,
      ...ip
    };
  });
};

type Props = {
  invoice: IInvoiceForm | IInvoice;
  pieces: Dictionary<IPiece>;
  operations: Dictionary<IOperation>;
  type: 'invoice' | 'quote';
} & ReactPDF.DocumentProps;

const InvoiceDocument: React.FC<Props> = ({
  invoice,
  operations,
  pieces,
  type,
  ...props
}) => {
  const buildedOperations = operationsBuilder(
    invoice.operations,
    pieces,
    operations
  );
  const slicedBuildedOperations = [];
  for (let i = 0, j = buildedOperations.length; i < j; i += 18) {
    slicedBuildedOperations.push(buildedOperations.slice(i, i + 18));
  }

  const buildedPieces = piecesBuilder(invoice.pieces, pieces);
  const slicedBuildedPieces = [];
  for (let i = 0, j = buildedPieces.length; i < j; i += 18) {
    slicedBuildedPieces.push(buildedPieces.slice(i, i + 18));
  }

  return (
    <Document {...props}>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageView}>
          <InvoicePage title="Récaputilatif" invoice={invoice} type={type}>
            <InvoiceSummary invoice={invoice} type={type} />
          </InvoicePage>
        </View>
      </Page>
      {invoice.operations.length > 0 &&
        slicedBuildedOperations.map((operationsSlice, i) => (
          <Page size="A4" style={styles.page} key={i}>
            <View style={styles.pageView}>
              <InvoicePage title="Main D'œuvre" invoice={invoice} type={type}>
                <Table
                  items={operationsSlice}
                  columns={operationsColumns}
                  tableRowsCount={18}
                  footerDescription={
                    i === slicedBuildedOperations.length - 1 ? (
                      <TableFooter
                        items={buildedOperations}
                        description={'S. Total M.O HT en TND'}
                      />
                    ) : undefined
                  }
                />
              </InvoicePage>
            </View>
          </Page>
        ))}
      {invoice.pieces.length > 0 &&
        slicedBuildedPieces.map((piecesSlice, i) => (
          <Page size="A4" style={styles.page} key={i}>
            <View style={styles.pageView}>
              <InvoicePage
                title="Fourniture & Pièces de Rechange"
                invoice={invoice}
                type={type}
              >
                <Table
                  items={piecesSlice}
                  columns={piecesColumns}
                  tableRowsCount={18}
                  footerDescription={
                    i === slicedBuildedPieces.length - 1 ? (
                      <TableFooter
                        items={buildedPieces}
                        description={'S. Total Pièces HT en TND'}
                      />
                    ) : undefined
                  }
                />
              </InvoicePage>
            </View>
          </Page>
        ))}
    </Document>
  );
};

export default InvoiceDocument;
