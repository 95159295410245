import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import { useAuth } from 'src/contexts';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { UsersApi } from 'src/api';
import { Form, FormInput, FormPhoneInput } from '../controls';
import { toast } from 'react-toastify';

const AccountProfileDetails: React.FC = () => {
  const { user } = useAuth();
  const mutation = useMutation(UsersApi.update, {
    onSuccess: () => {
      toast.success('Profile modifié avec succés');
    },
    onError: () => {
      toast.error("une erreur est survenue lors de l'édition");
    }
  });
  const methods = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
      email: user?.email
    }
  });

  const onSubmit = (body: any) => {
    if (user) {
      mutation.mutate({
        id: user?.id,
        body
      });
    }
  };

  return (
    <Form methods={methods} submitHandler={onSubmit}>
      <Card>
        <CardHeader
          subheader="Vous pouvez editer vos informations"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormInput
                fullWidth
                label="Prénom"
                required
                variant="outlined"
                name="first_name"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                fullWidth
                label="Nom"
                required
                variant="outlined"
                name="last_name"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                fullWidth
                label="Email"
                required
                variant="outlined"
                name="email"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormPhoneInput fullWidth label="Téléphone" name="phone" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={mutation.isLoading}
          >
            Enregistrer
          </Button>
        </Box>
      </Card>
    </Form>
  );
};
export default AccountProfileDetails;
