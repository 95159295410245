import { Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import logo from 'src/assets/images/logo.png';

const styles = StyleSheet.create({
  header: {
    height: 100,
    borderBottom: '1.5pt solid black',
    padding: 15
  },
  headerContent: {
    paddingLeft: 5,
    paddingRight: 5,
    height: 40,
    border: '1.5pt solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  headerLogo: {
    width: 141,
    height: 28,
    display: 'flex',
    alignSelf: 'center'
  },
  headerText: {
    fontSize: 8,
    display: 'flex',
    alignSelf: 'center'
  }
});

const InvoiceHeader: React.FC = () => (
  <View style={styles.header}>
    <View style={styles.headerContent}>
      <Image style={styles.headerLogo} src={logo} />
      <Text style={styles.headerText}>
        Vente Pièce de rechange Toute Marques{'\n'}
        Réparation et Peinture Carrosserie AUTO
      </Text>
    </View>
  </View>
);

export default InvoiceHeader;
