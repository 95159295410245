import { View, StyleSheet } from '@react-pdf/renderer';
import {
  IInvoice,
  IInvoiceForm,
  INVOICE_DURATION_UNITS_LABELS
} from 'src/types/invoice';
import TableCell from '../table/table-cell';
import TableHeader from '../table/table-header';
import TableRow from '../table/table-row';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: 'black'
  },
  labels: {
    backgroundColor: '#D6DBE5'
  }
});

const columns = [
  {
    key: 'header',
    name: 'Informations du Véhicule',
    width: '100%'
  }
];

type Props = {
  invoice: IInvoiceForm | IInvoice;
};
const InvoiceCarInfos: React.FC<Props> = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <>
        <TableRow>
          <TableCell styles={styles.labels} width={'20%'}>
            Marque
          </TableCell>
          <TableCell width={'30%'}>
            {invoice.client_car?.car.make_name}
          </TableCell>
          <TableCell styles={styles.labels} width={'20%'}>
            Ref Peinture
          </TableCell>
          <TableCell width={'30%'}>
            {invoice.client_car?.painting_ref}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell styles={styles.labels} width={'20%'}>
            Modele
          </TableCell>
          <TableCell width={'30%'}>{invoice.client_car?.car.model}</TableCell>
          <TableCell styles={styles.labels} width={'20%'}>
            Coupe
          </TableCell>
          <TableCell width={'30%'}>{invoice.client_car?.car.type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell styles={styles.labels} width={'20%'}>
            Matricule
          </TableCell>
          <TableCell width={'30%'}>
            {invoice.client_car?.plate_number}
          </TableCell>
          <TableCell styles={styles.labels} width={'20%'}>
            Durée Travaux
          </TableCell>
          <TableCell width={'30%'}>{`${invoice.working_duration} ${
            INVOICE_DURATION_UNITS_LABELS[invoice.working_duration_unit]
          }`}</TableCell>
        </TableRow>
      </>
    </View>
  );
};

export default InvoiceCarInfos;
