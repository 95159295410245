import { IClient } from 'src/types/client';
import { IUser } from 'src/types/user';

export const formatName = (user: IUser | null): string => {
  return user ? `${user.first_name} ${user.last_name.toUpperCase()}` : ' ';
};

export const formatClient = (client?: IClient | null): string => {
  return client ? `${client.title} ${client.name}` : ' ';
};
