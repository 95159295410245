import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice, IInvoiceForm } from 'src/types/invoice';
import InvoiceFooter from './invoice-footer';
import InvoiceHeader from './invoice-header';
import InvoiceHeaderInfos from './invoice-header-infos';
import InvoicePageTitle from './invoice-page-title';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 15
  },
  content: {
    border: '1.5pt solid black',
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15
  }
});

type Props = {
  title: string;
  invoice: IInvoiceForm | IInvoice;
  type: 'invoice' | 'quote';
};
const InvoicePage: React.FC<Props> = ({ invoice, title, type, children }) => {
  return (
    <>
      <InvoiceHeader />
      <View style={styles.container}>
        <InvoiceHeaderInfos invoice={invoice} type={type} />
        <View style={styles.content}>
          <InvoicePageTitle title={title} />
          {children}
        </View>
      </View>
      <InvoiceFooter />
    </>
  );
};
export default InvoicePage;
