import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Settings from './pages/Settings';
import TwoFactorChallenge from './pages/TwoFactorChallenge';
import { IUser } from './types/user';
import ListInvoices from './pages/Invoices/list';
import ListQuotes from './pages/Quotes/list';
import ListUsers from './pages/Users/list';
import ListClients from './pages/Clients/list';
import ListPartners from './pages/Partners/list';
import ListCars from './pages/Cars/list';
import ListPieces from './pages/Pieces/list';
import CreatePiece from './pages/Pieces/create';
import EditPiece from './pages/Pieces/edit';
import EditCar from './pages/Cars/edit';
import CreateCar from './pages/Cars/create';
import CreateUser from './pages/Users/create';
import CreatePartner from './pages/Partners/create';
import EditPartner from './pages/Partners/edit';
import CreateResponsible from './pages/Partners/Responsibles/create';
import EditResponsible from './pages/Partners/Responsibles/edit';
import CreateClient from './pages/Clients/create';
import EditClient from './pages/Clients/edit';
import DetailsClient from './pages/Clients/details';
import CreateClientCar from './pages/Clients/cars/create';
import EditClientCar from './pages/Clients/cars/edit';
import ListOperations from './pages/Operations/list';
import CreateOperation from './pages/Operations/create';
import EditOperation from './pages/Operations/edit';
import { PartialRouteObject } from 'react-router';
import CreateInvoice from './pages/Invoices/create';
import EditInvoice from './pages/Invoices/edit';
import DetailsPartner from './pages/Partners/details';
import CreateQuote from './pages/Quotes/create';
import EditQuote from './pages/Quotes/edit';
import DetailsInvoice from './pages/Invoices/details';
import DetailsQuote from './pages/Quotes/details';
import EditUser from './pages/Users/edit';
import CreateInvoiceFromQuote from './pages/Invoices/create-from-quote';
import ListRoles from './pages/Roles/list';
import CreateRole from './pages/Roles/create';
import EditRole from './pages/Roles/edit';
import DuplicateInvoice from './pages/Invoices/duplicate';
import DuplicateQuote from './pages/Quotes/duplicate';

const routes = (
  user: IUser | null,
  canNavigateTo: (permission: string, page: ReactNode) => ReactNode
): PartialRouteObject[] => [
  {
    path: 'app',
    element: user !== null ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'account', element: <Account /> },

      {
        path: 'invoices',
        element: canNavigateTo('invoices.list', <ListInvoices />)
      },
      {
        path: 'invoices/:id',
        element: canNavigateTo('invoices.show', <DetailsInvoice />)
      },
      {
        path: 'invoices/create',
        element: canNavigateTo('invoices.create', <CreateInvoice />)
      },
      {
        path: 'invoices/edit/:id',
        element: canNavigateTo('invoices.update', <EditInvoice />)
      },
      {
        path: 'invoices/create-from-quote/:id',
        element: <CreateInvoiceFromQuote />
      },
      {
        path: 'invoices/duplicate/:id',
        element: <DuplicateInvoice />
      },

      {
        path: 'quotes',
        element: canNavigateTo('quotes.list', <ListQuotes />)
      },
      {
        path: 'quotes/:id',
        element: canNavigateTo('quotes.show', <DetailsQuote />)
      },
      {
        path: 'quotes/create',
        element: canNavigateTo('quotes.create', <CreateQuote />)
      },
      {
        path: 'quotes/edit/:id',
        element: canNavigateTo('quotes.update', <EditQuote />)
      },
      {
        path: 'quotes/duplicate/:id',
        element: <DuplicateQuote />
      },

      {
        path: 'users',
        element: canNavigateTo('users.list', <ListUsers />)
      },
      {
        path: 'users/create',
        element: canNavigateTo('users.create', <CreateUser />)
      },
      {
        path: 'users/edit/:id',
        element: canNavigateTo('users.update', <EditUser />)
      },

      {
        path: 'roles',
        element: canNavigateTo('roles.list', <ListRoles />)
      },
      {
        path: 'roles/create',
        element: canNavigateTo('roles.create', <CreateRole />)
      },
      {
        path: 'roles/edit/:id',
        element: canNavigateTo('roles.update', <EditRole />)
      },

      {
        path: 'clients',
        element: canNavigateTo('clients.list', <ListClients />)
      },
      {
        path: 'clients/create',
        element: canNavigateTo('clients.create', <CreateClient />)
      },
      {
        path: 'clients/edit/:id',
        element: canNavigateTo('clients.update', <EditClient />)
      },
      {
        path: 'clients/:id',
        element: canNavigateTo('clients.show', <DetailsClient />)
      },
      {
        path: 'clients/:clientId/cars/create',
        element: canNavigateTo('client_cars.create', <CreateClientCar />)
      },
      {
        path: 'clients/:clientId/cars/edit/:id',
        element: canNavigateTo('client_cars.update', <EditClientCar />)
      },

      {
        path: 'partners',
        element: canNavigateTo('partners.list', <ListPartners />)
      },
      {
        path: 'partners/create',
        element: canNavigateTo('partners.create', <CreatePartner />)
      },
      {
        path: 'partners/edit/:id',
        element: canNavigateTo('partners.update', <EditPartner />)
      },
      {
        path: 'partners/:id',
        element: canNavigateTo('partners.show', <DetailsPartner />)
      },
      {
        path: 'partners/:partnerId/responsibles/create',
        element: canNavigateTo('responsibles.create', <CreateResponsible />)
      },
      {
        path: 'partners/:partnerId/responsibles/edit/:id',
        element: canNavigateTo('responsibles.update', <EditResponsible />)
      },

      {
        path: 'cars',
        element: canNavigateTo('cars.list', <ListCars />)
      },
      {
        path: 'cars/create',
        element: canNavigateTo('cars.create', <CreateCar />)
      },
      {
        path: 'cars/edit/:id',
        element: canNavigateTo('cars.update', <EditCar />)
      },

      {
        path: 'pieces',
        element: canNavigateTo('pieces.list', <ListPieces />)
      },
      {
        path: 'pieces/create',
        element: canNavigateTo('pieces.create', <CreatePiece />)
      },
      {
        path: 'pieces/edit/:id',
        element: canNavigateTo('pieces.update', <EditPiece />)
      },

      {
        path: 'operations',
        element: canNavigateTo('operations.list', <ListOperations />)
      },
      {
        path: 'operations/create',
        element: canNavigateTo('operations.create', <CreateOperation />)
      },
      {
        path: 'operations/edit/:id',
        element: canNavigateTo('operations.update', <EditOperation />)
      },

      {
        path: 'settings',
        element: canNavigateTo('settings.list', <Settings />)
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      /** needs anonymous */
      {
        path: '',
        element: user === null ? <Outlet /> : <Navigate to="/app/dashboard" />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'two-factor-challenge', element: <TwoFactorChallenge /> }
        ]
      },

      { path: '404', element: <NotFound /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
