import { publicApi } from './api';

const csrfCookie = async (): Promise<any> => {
  const { data } = await publicApi.get('/sanctum/csrf-cookie');
  return data;
};

const login = async (body: any): Promise<any> => {
  const { data } = await publicApi.post('/login', body);
  return data;
};

const twoFactorChallenge = async (body: any): Promise<any> => {
  const { data } = await publicApi.post('/two-factor-challenge', body);
  return data;
};

const twoFactorChallengeVerify = async (body: any): Promise<any> => {
  const { data } = await publicApi.post('/two-factor-challenge-verify', body);
  return data;
};

const getTwoFactorQRCode = async (): Promise<any> => {
  const { data } = await publicApi.get('/user/two-factor-qr-code');
  return data;
};
const getTwoFactorRecoveryCodes = async (): Promise<any> => {
  const { data } = await publicApi.get('/user/two-factor-recovery-codes');
  return data;
};

const logout = async (): Promise<any> => {
  const { data } = await publicApi.post('logout');
  return data;
};

export default {
  csrfCookie,
  login,
  twoFactorChallenge,
  twoFactorChallengeVerify,
  getTwoFactorQRCode,
  getTwoFactorRecoveryCodes,
  logout
};
