import { useEffect, useRef } from 'react';

const useDidMountEffect = (
  effect: React.EffectCallback,
  deps: React.DependencyList = []
): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) effect();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidMountEffect;
