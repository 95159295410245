import { useFormContext, Controller } from 'react-hook-form';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import './index.css';
import { get } from 'lodash-es';

type Props = TextFieldProps & {
  name: string;
};

const FormInput: React.FC<Props> = ({ name, defaultValue, ...rest }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const errorMessage = get(errors, `${name}.message`);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          sx={{ width: '100%' }}
          InputLabelProps={{
            className: rest.required ? 'required-label' : '',
            required: rest.required || false
          }}
          error={errorMessage}
          helperText={<div className="whitespace-pre-line">{errorMessage}</div>}
          {...rest}
          {...field}
        />
      )}
    />
  );
};
export default FormInput;
