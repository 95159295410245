import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppProviders } from './contexts';
import Routers from './app';
import moment from 'moment';
import 'moment/locale/fr';
import './index.css';

moment().locale('fr');
ReactDOM.render(
  <BrowserRouter>
    <AppProviders>
      <Routers />
    </AppProviders>
  </BrowserRouter>,
  document.getElementById('root')
);
