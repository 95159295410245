import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const rowStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    height: 24,
    fontStyle: 'bold'
  }
});

type Props = {
  styles?: any;
};

const TableRow: React.FC<Props> = ({ styles, children }) => {
  return <View style={{ ...rowStyles.row, ...styles }}>{children}</View>;
};

export default TableRow;
