import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AuthenticationApi } from 'src/api';

type QrCode = {
  svg: string;
};

const TwoFactorQrCode: React.FC = () => {
  const { data: qrCode, isLoading } = useQuery<QrCode, AxiosError>(
    '2fa-qr-code',
    () => AuthenticationApi.getTwoFactorQRCode()
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item>
        <Typography align="center" color="primary">
          Enregister ce QR code dans l'application d'Authentification de votre
          choix pour activer l'authentication à deux facteurs
        </Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: qrCode?.svg || '' }} />
        )}
      </Grid>
      <Grid item>
        <Typography align="center" color="primary">
          Pitstop entregister, vous verrez des codes de Vérification à 6
          chiffres
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TwoFactorQrCode;
