export interface IOperation {
  id: number;
  label: string;
  volume: number;
  price?: number | null;
  type: OperationTypesEnum;
  created_at?: Date;
  updated_at?: Date;
}

export enum OperationTypesEnum {
  REPAIR = 'repair',
  REMPLACEMENT = 'replacement',
  PAINTING = 'painting'
}
