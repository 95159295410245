import { makeStyles, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  to: string;
  target?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.primary.main,
      borderRadius: 3,
      color: theme.palette.primary.contrastText,
      width: '30px',
      height: '30px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1rem 0.5rem'
    }
  };
});

const IconLink: React.FC<Props> = ({
  title,
  to,
  target = '_self',
  children
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <Link className={classes.root} to={to} target={target}>
        {children}
      </Link>
    </Tooltip>
  );
};

export default IconLink;
