import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { PartnersApi } from 'src/api';
import PartnerForm from 'src/components/partners/form';
import { IPartner } from 'src/types/partner';

const EditPartner: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: partner, isLoading } = useQuery(
    ['partners', id],
    () => PartnersApi.get(id),
    {
      onError: () => {
        navigate('/app/partners');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation(PartnersApi.update, {
    onSuccess: () => {
      toast.success('Partenaire modifié avec succés');
      queryClient.invalidateQueries(['partners']).catch(() => {});
      navigate('/app/partners');
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IPartner>) => {
    mutation.mutate({
      id: id,
      body: data
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'un partenaire | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          py: '2rem'
        }}
      >
        <Card
          sx={{
            py: '2rem',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'un partenaire</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <PartnerForm onSubmit={onSubmit} defaultValues={partner} />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditPartner;
