import { LocaleInterface } from './locales/locale.interface';

type ConverterOptions = {
  currency?: boolean;
  ignoreDecimal?: boolean;
  ignoreZeroCurrency?: boolean;
};

const DefaultConverterOptions: ConverterOptions = {
  currency: false,
  ignoreDecimal: false,
  ignoreZeroCurrency: false
};

type ToWordsOptions = {
  localeCode?: string;
  converterOptions?: ConverterOptions;
};

interface ConstructorOf<T> {
  new (): T;
}

export class ToWords {
  private options: ToWordsOptions = {};
  private locale: LocaleInterface | undefined = undefined;

  constructor(options: ToWordsOptions = {}) {
    this.options = Object.assign(
      {
        localeCode: 'en-IN',
        converterOptions: DefaultConverterOptions
      },
      options
    );
  }

  private getLocaleClass(): ConstructorOf<LocaleInterface> {
    /* eslint-disable @typescript-eslint/no-var-requires */
    switch (this.options.localeCode) {
      case 'tn-TN':
        return require('./locales/tn-TN').Locale;
    }
    /* eslint-enable @typescript-eslint/no-var-requires */
    throw new Error(`Unknown Locale "${this.options.localeCode as string}"`);
  }

  getLocale(): LocaleInterface {
    if (this.locale === undefined) {
      const LocaleClass = this.getLocaleClass();
      this.locale = new LocaleClass();
    }
    return this.locale;
  }

  convert(number: number | string, options: ConverterOptions = {}): string {
    options = Object.assign({}, this.options.converterOptions, options);

    if (!this.isValidNumber(number)) {
      throw new Error(`Invalid Number "${number}"`);
    }

    const locale = this.getLocale();

    let isFloat = this.isFloat(number);
    if (options.ignoreDecimal) {
      number = Number.parseInt(number.toString());
      isFloat = false;
    }

    const isNegativeNumber =
      typeof number === 'string' ? number[0] === '-' : number < 0;
    if (isNegativeNumber) {
      number = typeof number === 'string' ? number.slice(1) : Math.abs(number);
    }

    if (options.currency) {
      number = typeof number === 'string' ? number : this.toFixed(number);
      const floatNumber = typeof number === 'string' ? Number(number) : number;
      // Extra check for isFloat to overcome 1.999 rounding off to 2
      isFloat = this.isFloat(floatNumber);
      const split = number.toString().split('.');

      let words = `${this.convertInternal(Number(split[0]), options)}${
        locale.currency.plural ? ` ${locale.currency.plural}` : ''
      }`;
      const isNumberZero = floatNumber >= 0 && floatNumber < 1;
      const ignoreZero =
        options.ignoreZeroCurrency ||
        (locale.options?.ignoreZeroInDecimals && floatNumber !== 0);

      if (isNumberZero && ignoreZero) {
        words = '';
      }
      let wordsWithDecimal = '';
      if (isFloat) {
        if (!isNumberZero || !ignoreZero) {
          wordsWithDecimal += ` ${locale.texts.and} `;
        }
        const decimalLengthWord =
          locale?.decimalLengthWordMapping?.[split[1].length];
        wordsWithDecimal += `${Number(split[1])}${
          decimalLengthWord ? ` ${decimalLengthWord}` : ''
        } ${locale.currency.fractionalUnit.plural}`;
      } else if (locale.decimalLengthWordMapping && words !== '') {
        words += ` ${locale.currency.fractionalUnit.plural}`;
      }
      const isEmpty = words.length <= 0 && wordsWithDecimal.length <= 0;
      return (
        (!isEmpty && isNegativeNumber ? `${locale.texts.minus} ` : '') +
        words +
        wordsWithDecimal +
        (!isEmpty && locale.texts.only ? ` ${locale.texts.only}` : '')
      );
    } else {
      const isNumberZero = number >= 0 && number < 1;
      const split = number.toString().split('.');
      const ignoreZero = isNumberZero && locale.options?.ignoreZeroInDecimals;
      const words =
        isFloat && ignoreZero
          ? ''
          : this.convertInternal(Number(split[0]), options);
      let wordsWithDecimal = '';

      if (isFloat) {
        const decimalLengthWord =
          locale?.decimalLengthWordMapping?.[split[1].length];
        if (!ignoreZero) wordsWithDecimal += ` ${locale.texts.point} `;
        if (split[1].startsWith('0') && !locale.decimalLengthWordMapping) {
          const zeroWords = [];
          for (const num of split[1]) {
            zeroWords.push(this.convertInternal(Number(num)));
          }
          wordsWithDecimal += zeroWords.join(' ');
        } else {
          wordsWithDecimal += `${this.convertInternal(
            Number(split[1]),
            options
          )}${decimalLengthWord ? ` ${decimalLengthWord}` : ''}`;
        }
      }
      const isEmpty = words.length <= 0 && wordsWithDecimal.length <= 0;
      return (
        (!isEmpty && isNegativeNumber ? `${locale.texts.minus} ` : '') +
        words +
        wordsWithDecimal
      );
    }
  }

  private convertInternal(number: number, options = {}): string {
    const locale = this.getLocale();
    const splitWord = locale.options?.splitWord
      ? `${locale.options?.splitWord} `
      : '';
    const pluralMark = locale.options?.pluralMark
      ? `${locale.options?.pluralMark}`
      : '';
    const match = locale.numberWordsMapping.find((elem) => {
      return number >= elem.number;
    });

    if (!match) {
      throw new Error(`Invalid Number "${number}"`);
    }

    let words = '';
    if (number <= 100 || (number < 1000 && locale.options?.namedLessThan1000)) {
      words += match.value;
      number -= match.number;
      if (number > 0) {
        words += ` ${splitWord}${this.convertInternal(number, options)}`;
      }
    } else {
      const quotient = Math.floor(number / match.number);
      const remainder = number % match.number;
      const matchValue =
        quotient > 1 &&
        locale.options?.pluralWords?.find((word) => word === match.value)
          ? match.value + pluralMark
          : match.value;
      if (remainder > 0) {
        if (quotient == 1 && locale.options?.ignoreOneForWords) {
          return `${matchValue} ${splitWord}${this.convertInternal(
            remainder,
            options
          )}`;
        } else {
          return `${this.convertInternal(
            quotient,
            options
          )} ${matchValue} ${splitWord}${this.convertInternal(
            remainder,
            options
          )}`;
        }
      } else {
        if (quotient == 1 && locale.options?.ignoreOneForWords) {
          return `${matchValue}`;
        } else {
          return `${this.convertInternal(quotient, options)} ${matchValue}`;
        }
      }
    }
    return words;
  }

  toFixed(number: number, precision = 3): number {
    return Number(Number(number).toFixed(precision));
  }

  isFloat(number: number | string): boolean {
    return Number(number) === number && number % 1 !== 0;
  }

  isValidNumber(number: number | string): boolean {
    return !isNaN(parseFloat(number as string)) && isFinite(number as number);
  }
}
