import { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TwoFactorQrCode from './two-factor-qr-code';
import TwoFactorChallengeVerification from './two-factor-challenge-verification';
import TwoFactorBackupCodes from './two-factor-backup-codes';
import TwoFactorCompelted from './two-factor-completed';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    closeButton: {
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ['Enregistrer', 'Vérification', 'Backup'];
}

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return <TwoFactorQrCode />;
    case 1:
      return <TwoFactorChallengeVerification />;
    case 2:
      return <TwoFactorBackupCodes />;
    default:
      return 'Unknown stepIndex';
  }
}

const TwoFactorQrCodeModal: React.FC<Props> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Dialog
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') handleClose();
        }}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle id="dialog-title" onClose={handleClose}>
          Authentification à deux facteur
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Box className={classes.instructions} paddingY={5}>
                    <TwoFactorCompelted onClose={handleClose} />
                  </Box>
                </div>
              ) : (
                <div>
                  <Box className={classes.instructions} paddingY={5}>
                    {getStepContent(activeStep)}
                  </Box>
                  <Box textAlign="right">
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Retour
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TwoFactorQrCodeModal;
