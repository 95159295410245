import { useFormContext, Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Fragment } from 'react';
type Props = CheckboxProps & {
  name: string;
  helperText?: string;
  label: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
};

const FormCheckBox: React.FC<Props> = ({
  name,
  label,
  labelPlacement,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={
              <Checkbox
                {...rest}
                name={name}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                ref={field.ref}
              />
            }
          />
        )}
      />
    </Fragment>
  );
};
export default FormCheckBox;
