import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 15,
    backgroundColor: '#44546A',
    border: '1.5pt solid black',
    height: 28
  },
  title: {
    color: 'white',
    fontSize: 15,
    display: 'flex',
    alignSelf: 'center'
  }
});

type Props = {
  title: string;
};
const InvoicePageTitle: React.FC<Props> = ({ title }) => {
  return (
    <View style={styles.titleContainer}>
      <Text
        style={styles.title}
        render={({ pageNumber, totalPages }) => {
          return `${title} ( Page ${pageNumber} / ${totalPages} )`;
        }}
      />
    </View>
  );
};

export default InvoicePageTitle;
