import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IOperation, OperationTypesEnum } from 'src/types/operation';
import { Form, FormInput } from '../controls';
import FormSelect from '../controls/select';

const OperationTypes = [
  {
    label: 'Réparation',
    value: OperationTypesEnum.REPAIR
  },
  {
    label: 'Remplacement',
    value: OperationTypesEnum.REMPLACEMENT
  },
  {
    label: 'Peinture',
    value: OperationTypesEnum.PAINTING
  }
];
type Props = {
  defaultValues?: IOperation;
  onSubmit: (data: Partial<IOperation>) => void;
  errors?: any;
};

const DEFAULT_VALUES = {
  label: '',
  volume: 0,
  price: null,
  type: OperationTypesEnum.REPAIR
};
const OperationForm: React.FC<Props> = ({
  defaultValues,
  onSubmit,
  errors
}) => {
  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_VALUES
  });

  const { setValue, reset, setError } = methods;

  useEffect(() => {
    if (errors?.validations) {
      for (const field in errors.validations) {
        setError(field as keyof IOperation, {
          type: 'manual',
          message: errors.validations[field].join('\n')
        });
      }
    }
  }, [errors, setError]);

  const [hasCustomPrice, setHasCustomPrice] = useState(
    defaultValues?.price !== null
  );

  useEffect(() => {
    if (hasCustomPrice === false) {
      setValue('price', null);
    }
  }, [hasCustomPrice, setValue]);

  return (
    <Container>
      <Form methods={methods} submitHandler={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <FormSelect label="Type" name="type" options={OperationTypes} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormInput label="Label" name="label" />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormInput label="Volume" name="volume" type="number" />
          </Grid>
          <Grid container item xs={6} sm={6}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasCustomPrice}
                    onChange={(e) => setHasCustomPrice(e.target.checked)}
                  />
                }
                label="Taux horaire personalisé"
              />
            </Grid>
            {hasCustomPrice && (
              <Grid item xs={6}>
                <FormInput label="Prix" name="price" type="number" />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-row justify-center space-x-5 py-5"
          >
            <Button
              onClick={() => reset()}
              variant="contained"
              color="secondary"
            >
              Réinitialiser
            </Button>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default OperationForm;
