import { Box, Card, Container, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OperationsApi } from 'src/api';
import OperationForm from 'src/components/operations/form';
import { IOperation } from 'src/types/operation';

const CreateOperation: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation<IOperation, AxiosError, Partial<IOperation>>(
    OperationsApi.create,
    {
      onSuccess: () => {
        toast.success('Opération ajouté avec succés');
        queryClient.invalidateQueries(['operations']).catch(() => {});
        navigate('/app/operations');
      },
      onError: () => {
        toast.error("une erreur est survenue lors de l'ajout");
      }
    }
  );

  const onSubmit = (data: Partial<IOperation>) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Création d'une opération | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'une opération</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <OperationForm
              onSubmit={onSubmit}
              errors={mutation?.error?.response?.data}
            />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateOperation;
