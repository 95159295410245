import { Box, Container, Typography } from '@material-ui/core';
import {
  GridCellParams,
  GridColDef
} from '@material-ui/data-grid';
import { InvoicesApi } from 'src/api';
import { Helmet } from 'react-helmet';
import DataTable from 'src/components/datatable';
import {
  AddLink,
  DeleteButton,
  DetailsLink,
  EditLink,
  IconLink
} from 'src/components/common';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { IClient } from 'src/types/client';
import { ICar } from 'src/types/car';
import { IClientCar } from 'src/types/client-car';
import {
  IInvoice,
  INVOICE_PAYEMENT_STATUSES_LABELS,
  PaymentStatusEnum,
} from 'src/types';
import { useAuth } from 'src/contexts';

const ListInvoices: React.FC = () => {
  const { can } = useAuth();
  const columns: GridColDef[] = [
    {
      field: 'internal_id',
      headerName: 'ID',
      width: 100,
      sortable: true,
      filterable: true,
      type: 'number'
    },
    {
      field: 'client.name',
      headerName: 'Client',
      width: 200,
      type:'string',
      sortable: false,
      filterable: true,
      valueGetter: (params) => {
        const client = params.row.client as IClient;
        return `${client.title} ${client.name}`;
      }
    },
    {
      field: 'client_car.car.make_name',
      headerName: 'Marque',
      width: 200,
      type: 'string',
      sortable: false,
      filterable: true,
      valueGetter: (params) => {
        const car = params.row.client_car.car as ICar;
        return car.make_name;
      }
    },
    {
      field: 'client_car.car.model',
      headerName: 'Modéle',
      width: 200,
      type: 'string',
      sortable: false,
      filterable: true,
      valueGetter: (params) => {
        const car = params.row.client_car.car as ICar;
        return car.model;
      }
    },
    {
      field: 'client_car.plate_number',
      headerName: 'Immatriculation',
      width: 200,
      sortable: false,
      filterable: true,
      type: 'string',
      valueGetter: (params) => {
        const clientCar = params.row.client_car as IClientCar;
        return clientCar.plate_number;
      }
    },
    {
      field: 'payment_status',
      headerName: 'Status du paiement',
      width: 200,
      sortable: false,
      filterable: true,
      type: 'string',
      valueGetter: (params) => {
        const paymentStatus = params.row.payment_status as PaymentStatusEnum;
        return INVOICE_PAYEMENT_STATUSES_LABELS[paymentStatus];
      }
    },
    {
      field: 'total',
      headerName: 'Totale',
      width: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const row = params.row as IInvoice;
        return row.operations_sub_total + row.pieces_sub_total;
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      sortable: true,
      filterable: true,
      type: 'date',
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        return (
          <Box className="flex justify-center w-full">
            {can('invoices.show') && (
              <DetailsLink to={`${params.id as string}`} />
            )}
            {can('invoices.update') && <EditLink to={`edit/${id}`} />}
            {can('invoices.create') && (
              <IconLink title="Dupliquer" to={`/app/invoices/duplicate/${id}`}>
                <FileCopyIcon className="w-full h-full" />
              </IconLink>
            )}
            {can('invoices.delete') && (
              <DeleteButton
                id={id}
                remove={InvoicesApi.remove}
                queryKey={'invoices'}
              />
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Factures | Pitstop</title>
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          py: '1rem'
        }}
      >
        <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
          <Typography variant="h2">
            Factures
            {can('invoices.create') && <AddLink />}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
          <DataTable
            queryKey={['invoices']}
            paginator={InvoicesApi.paginate}
            columns={columns}
          />
        </Box>
      </Container>
    </>
  );
};

export default ListInvoices;
