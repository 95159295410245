import qs from 'qs';
import { Id } from 'src/types/common';
import { IPaginateQuery, IPaginationResult } from 'src/types/paginate';
import { IUserPayload, IUser } from 'src/types/user';
import privateApi, { publicApi } from './api';

const user = async (): Promise<IUser> => {
  const { data } = await privateApi.get('/user');
  return data;
};

const enableTwoFactor = async (): Promise<any> => {
  const { data } = await publicApi.post('/user/two-factor-authentication');
  return data;
};

const disableTwoFactor = async (): Promise<any> => {
  const { data } = await publicApi.delete('/user/two-factor-authentication');
  return data;
};

const paginate = async (
  page = 1,
  limit = 20,
  query?: IPaginateQuery
): Promise<IPaginationResult<IUser>> => {
  const { data } = await privateApi.get(`/users?page=${page}&limit=${limit}`, {
    params: { query },
    paramsSerializer: qs.stringify
  });
  return data;
};

const get = async (id: Id): Promise<IUser> => {
  const { data } = await privateApi.get(`/users/${id}`);
  return data;
};

const create = async (body: IUserPayload): Promise<IUser> => {
  const { data } = await privateApi.post('/users', body);
  return data;
};

const update = async ({
  id,
  body
}: {
  id: number;
  body: IUserPayload;
}): Promise<IUser> => {
  const { data } = await privateApi.patch(`users/${id}`, body);
  return data;
};

const remove = async (id: Id): Promise<void> => {
  const { data } = await privateApi.delete(`/users/${id}`);
  return data;
};

const updatePassword = async (body: any): Promise<IUser> => {
  const { data } = await privateApi.post('/users/update_password', body);
  return data;
};

export default {
  user,
  enableTwoFactor,
  disableTwoFactor,
  paginate,
  get,
  create,
  update,
  remove,
  updatePassword
};
