import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UsersApi } from 'src/api';
import { AxiosError } from 'axios';
import { IUserPayload } from 'src/types/user';
import UserForm from 'src/components/users/form';

const EditUser: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  console.log(id);
  const { data: user, isLoading } = useQuery(
    ['users', id],
    () => UsersApi.get(id),
    {
      onError: () => {
        navigate('/app/users');
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation<any, AxiosError, any>(UsersApi.update, {
    onSuccess: () => {
      toast.success('Utilisateur modifié avec succés');
      queryClient.invalidateQueries(['users']).catch(() => {});
      navigate('/app/users');
    },
    onError: (error) => {
      if (error.response?.data) {
        toast.error(error.response?.data);
      } else {
        toast.error("une erreur est survenue lors de l'édition");
      }
    }
  });

  const onSubmit = (body: Partial<IUserPayload>) => {
    mutation.mutate({ id, body });
  };

  return (
    <>
      <Helmet>
        <title>Modifier un utilisateur | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Modifier un utilisateur</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <UserForm
                  onSubmit={onSubmit}
                  defaultValues={user}
                  mode="UPDATE"
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditUser;
