import { CircularProgress, Typography, Box } from '@material-ui/core';
import { useQuery } from 'react-query';
import { ClientsApi } from 'src/api';
import { Id } from 'src/types/common';

type Props = {
  clientId: Id;
};
const ClientDetails: React.FC<Props> = ({ clientId }) => {
  const { data: client, isLoading } = useQuery(['clients', clientId], () =>
    ClientsApi.get(clientId)
  );
  return (
    <Box border={2} p={1} my={2}>
      {isLoading || !client ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h3">Détails du client</Typography>
          <Typography variant="body1" color="black">
            Nom : {`${client.title} ${client.name}`}
          </Typography>
          <Typography variant="body1" color="black">
            Matricule fiscale : {client.registration_number}
          </Typography>
          <Typography variant="body1" color="black">
            Email : {client.email}
          </Typography>
          <Typography variant="body1" color="black">
            Téléphone : {client.phone}
          </Typography>
          <Typography variant="body1" color="black">
            Adresse : {client.address}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ClientDetails;
