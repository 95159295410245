import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { useFormContext } from 'react-hook-form';
import { IInvoiceForm, InvoiceType } from 'src/types/invoice';
import { InvoiceButton } from '.';

type Props = {
  type: InvoiceType;
};
const InvoiceSummary: React.FC<Props> = ({ type }) => {
  const methods = useFormContext();
  const invoice = methods.getValues() as IInvoiceForm;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h4" align="center">
        {type === 'invoice'
          ? 'Vous pouvez voir la facture avant de la créer'
          : 'Vous pouvez voir le devis avant de le créer'}
      </Typography>
      <Box display="flex" justifyContent="center" marginTop="1rem">
        <InvoiceButton {...{ invoice, type }} />
      </Box>
    </Box>
  );
};

export default InvoiceSummary;
