import { useRoutes } from 'react-router';
import { useAuth } from './contexts';
import routes from './routes';

const Routers: React.FC = () => {
  const { user, isUserLoading, canNavigateTo } = useAuth();
  const routing = useRoutes(routes(user, canNavigateTo));

  return <>{isUserLoading ? <div>Loading ...</div> : routing}</>;
};

export default Routers;
