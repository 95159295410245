import { Box, Card, Container, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RolesApi } from 'src/api';
import RoleForm from 'src/components/roles/form';
import { IRole } from 'src/types';

const CreateRole: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation<IRole, AxiosError, Partial<IRole>>(
    RolesApi.create,
    {
      onSuccess: () => {
        toast.success('Rôle ajouté avec succés');
        queryClient.invalidateQueries(['roles']).catch(() => {});
        navigate('/app/roles');
      },
      onError: () => {
        toast.error("une erreur est survenue lors de l'ajout");
      }
    }
  );

  const onSubmit = (data: Partial<IRole>) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Création d'un rôle | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">Création d'un rôle</Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <RoleForm
              onSubmit={onSubmit}
            />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateRole;
