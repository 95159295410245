import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ResponsiblesApi } from 'src/api';
import ResponsibleForm from 'src/components/responsibles/form';
import { IResponsible } from 'src/types/responsible';

const EditResponsible: React.FC = () => {
  const { id, partnerId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: responsible, isLoading } = useQuery(
    ['responsibles', id],
    () => ResponsiblesApi.get(id),
    {
      onError: () => {
        navigate(`/app/responsibles/${partnerId}`);
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation(ResponsiblesApi.update, {
    onSuccess: () => {
      toast.success('Responsable modifié avec succés');
      queryClient
        .invalidateQueries(['partners', 'responsibles', partnerId])
        .catch(() => {});
      navigate(`/app/partners/${partnerId}`);
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IResponsible>) => {
    mutation.mutate({
      id: id,
      body: { ...data, partner_id: parseInt(partnerId) }
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'un responsable | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">Édition d'un responsable</Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <ResponsibleForm
                  onSubmit={onSubmit}
                  defaultValues={responsible}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditResponsible;
